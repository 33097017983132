import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";

const vuexMapFields = require("vuex-map-fields");
const updateField = vuexMapFields.updateField;
const getField = vuexMapFields.getField;

import * as moment from "moment";
import "moment/locale/en-gb";

import data from "./modules/data";
import scan from "./modules/scan";
import settings from "./modules/settings";
import user from "./modules/user";
import popup from "./modules/popup-dialog";
import upload from "./modules/upload";
import scale from "./modules/scale";
import count from "./modules/scanning-functions/count";
import headCount from "./modules/scanning-functions/head-count";
import autoCount from "./modules/scanning-functions/auto-count";
import dispatchToAbattoir from "./modules/scanning-functions/dispatch-to-abattoir";
import groupWeigh from "./modules/scanning-functions/group-weigh";
import power from "./modules/power-settings";
import selection from "./modules/selection";
import certificates from "./modules/certificates";
import sync from "./modules/sync";

import * as packageJson from "../../package.json";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import { rtcEmitter } from "@/main";

Vue.use(Vuex);

type EnvType = "production" | "staging" | "testing" | "undetermined";

async function graphQl(o: {
  jwt: string;
  url: string;
  query: string;
  variables: any;
  useRtc: boolean;
  timeout?: number;
  onprogress?: ((num: number, progress: number, total: number) => void);
}) {
  if (!o.useRtc) {
    let options: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + o.jwt },
      body: JSON.stringify({ query: o.query, variables: o.variables })
    };
    let response = await fetch(o.url, options);

    if (response.ok) {
      let json = await response.json();

      console.log("json received");
      //console.log(json);
      return json;
    } else {
      let errorJson = await response.json();
      let s = "";
      for (let error of errorJson.errors) {
        s += `\n${error.message}`;
      }
      throw Error("Response not ok: " + s);
    }
  } /*useRtc*/ else {
    const graphQLRetryPrematureConnection = async () => {
      const attemptGraphQL = () => new Promise((resolve, reject) => {
        let result: any;
        const client = createClient(reject, (num, progress, total) => {
          //console.log(`onprogress(${num}, ${progress}, ${total})`);
          o?.onprogress?.(num, progress, total);
        });
        if (!client) return; //reject is called if no data channel found (causing client to return as undefined)
        client.subscribe(
          {
            query: o.query,
            variables: o.variables,
          },
          {
            next: (data: any) => {
              if (data.errors) for (const err of data.errors) console.error(err.message);
              if (data.data === null && data.errors?.length)
                reject(data.errors.map((e: { message: string; }) => e.message).join("\n"));
              result = data;
            },
            error: (err) => { console.error("ERROR REJECT", err); reject(err); },
            complete: () => resolve(result)
          }
        );
      });

      let tries = 0;
      const MAX_TRIES = 3;
      let e;
      while (tries < MAX_TRIES) {
        try {
          const r = await attemptGraphQL();
          return r;
        } catch (err) {
          //ignore and try again
          e = err;
          console.warn(`try number ${tries + 1} graphql error: ${err}`);
          tries++;
        }
      }
      throw e;
    };
    if (o.timeout === undefined) o.timeout = 5 * 60 * 1000;
    if (o?.timeout !== undefined) {
      const timeout: number = o?.timeout as number;
      const timeoutPromise = new Promise((_, reject) => { setTimeout(() => reject("request timeout"), timeout); });
      let onRtcConnectionChange: (ev: any) => void = (_) => { };
      const connectionErrorPromise = new Promise((_, reject) => {
        onRtcConnectionChange = (ev: any) => {
          console.log("onRtcConnectionChange", ev);
          if (ev === "disconnected") reject("disconnected during request");
          //rtcEmitter.off("connectionEvent", onRtcConnectionChange);
        };
        rtcEmitter.on("connectionEvent", onRtcConnectionChange);

      });

      const p = await Promise.race([graphQLRetryPrematureConnection(), timeoutPromise, connectionErrorPromise]);
      rtcEmitter.off("connectionEvent", onRtcConnectionChange);
      return p;
    } else {
      return await graphQLRetryPrematureConnection();
    }
  }
}

export class RootState {
  version: string = packageJson.version;
  snackbarMessage: string = "";
  snackbarVisible: boolean = false;
  moment: any = moment;
  environment: EnvType = "undetermined";
  isOrca: boolean = false;
  storage: { [uid: string]: { [guid: string]: any; }; } = {};
  navFuncs: { save?: () => void; back?: () => void; } = {};
  rtcSignalling: "disconnected" | "local" | "cloud" = "disconnected";
  connectedToProxy: boolean = false;
}

export default new Vuex.Store<RootState>({
  state: new RootState(),
  mutations: {
    snackbar(state: RootState, payload: { message: string; }) {
      state.snackbarMessage = payload.message;
      state.snackbarVisible = true;
    },
    snackbarHide(state: RootState) {
      state.snackbarVisible = false;
    },
    environment(state: RootState, payload: EnvType) {
      state.environment = payload;
    },
    isOrca(state: RootState, payload: boolean) {
      state.isOrca = payload;
    },
    rtcSignalling(state: RootState, payload: "disconnected" | "local" | "cloud") {
      state.rtcSignalling = payload;
    },
    connectedToProxy(state: any, payload: boolean) {
      state.connectedToProxy = payload;
    },
    updateUserLocationField(state: any, field: { path: string; value: any; }) {
      let uid = state.user.user.uid;
      let guid = state.user.location.guid;
      state.storage[uid] = state.storage[uid] || {};
      state.storage[uid][guid] = state.storage[uid][guid] || {};

      /*
        This is done to try fix the "doesn't-work-on-first-load" issue.
        Before setting variable, check if "root" variable exists (i.e "obj" of "obj.x.y.z").
        
        If not then update storage, but afterwards set storage to a copy if itself.
        This seems to wake Vue up and rebuild its reactive value tree.
      */
      const t = field.path.split(/[.[\]]+/); //regex copied from https://github.com/maoberlehner/vuex-map-fields/blob/master/src/index.js
      const updateStorage = t.length && state.storage[uid][guid][t[0]] === undefined;

      updateField(state.storage[uid][guid], field);

      if (updateStorage) state.storage = { ...state.storage };
    },
    navFuncs(state: RootState, payload: { save?: () => void; back?: () => void; }) {
      state.navFuncs = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    },
    updateField: vuexMapFields.updateField
  },
  actions: {
    onAppCreated(context: any) {
      console.log("onAppCreated");
      context.dispatch("data/onAppCreated");
      context.dispatch("scan/onAppCreated");
      context.dispatch("sync/onAppCreated");
      context.commit("upload/setIsBusyUploading", false);
      context.commit("scale/reset");
    },
    async graphQl(
      context: ActionContext<RootState, any>,
      o: {
        gql: string;
        variables?: { [key: string]: any; };
        jwt: string;
        destination?: "feeder-server" | "node" | "cloud";
        onprogress?: ((num: number, progress: number, total: number) => void);
        timeout?: number
      }
    ) {
      if (!o.jwt) o.jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });
      //if (!o.destination) o.destination = "cloud";
      if (!o.destination) o.destination = "node";
      let url = o.destination === "cloud" ? context.getters["urlCloud"]() : context.getters["urlNode"]();

      if (o.destination === "feeder-server") {
        if (context.state.environment === "production") url = "https://pi.gigalot.systems:7777/feeder";
        else if (context.state.environment === "staging") {
          console.log("graphQl: staging environment detected, redirecting feeder-server destination to https://pi.gigalot.systems:7766/office");
          url = "https://pi.gigalot.systems:7766/office";
        } else {
          console.log(`graphQl: testing environment detected, redirecting feeder-server destination to ${context.getters["urlNode"]()}/office`);
          url = `${context.getters["urlNode"]()}/office`;
        }
      }

      console.log(`graphQl url: ${url}`);
      console.log(o.gql.split("\n")[0]);

      if (o.destination === "cloud") {
        url += "/field";
        return graphQl({ jwt: o.jwt, url, query: o.gql, variables: o.variables, useRtc: false, timeout: o.timeout, onprogress: o.onprogress });
      } else {
        url += "/office";
        // //Try locally first
        // try {
        //   return await graphQl(o.jwt, url, o.gql, o.variables, false);
        // } catch (err) {
        //   //If local doesn't work then try RTC
        //   console.error(err);
        //   console.log("Local graphQl failed, switching to RTC.");
        //   return await graphQl(o.jwt, url, o.gql, o.variables, true);
        // }
        if (context.state.rtcSignalling === "disconnected") throw Error("Not connected");
        return graphQl({ jwt: o.jwt, url, query: o.gql, variables: o.variables, useRtc: true, onprogress: o.onprogress });
      }
    }
  },
  getters: {
    getField: vuexMapFields.getField,
    getUserLocationField(state: any): any {
      let uid = state.user.user.uid;
      let guid = state.user.location.guid;
      state.storage[uid] = state.storage[uid] || {};
      state.storage[uid][guid] = state.storage[uid][guid] || {};
      return getField(state.storage[uid][guid]);
    },
    storage(state: any) {
      return () => {
        let uid = state.user.user.uid;
        let guid = state.user.location.guid;
        state.storage[uid] = state.storage[uid] || {};
        state.storage[uid][guid] = state.storage[uid][guid] || {};
        return state.storage[uid][guid];
      };
    },
    // backendUrl(state: RootState, getters: any, rootState: any, rootGetters: any) {
    //   return () => {
    //     switch (state.environment) {
    //       case "production":
    //         return "https://backend.gigalot.co.za";
    //       case "staging":
    //       case "testing":
    //         return "https://backend.gigalot.systems";
    //       case "undetermined":
    //       default:
    //         throw Error("No environment detected! (Expected to be production, staging, or testing)");
    //     }
    //   };
    // },
    urlNode(state: RootState) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://pi.gigalot.systems:7766"; //must be https
          case "staging":
          case "testing":
            return "https://pi.gigalot.systems:7766"; //must be https
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    urlCloud(state: RootState) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/fieldAppResolver";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/fieldAppResolver";
          case "testing":
            return "http://localhost:5001/gigalot-testing/europe-west1/fieldAppResolver";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    urlCert(state: RootState) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/getProxyCertificate";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/getProxyCertificate";
          case "testing":
            return "http://127.0.0.1:5001/gigalot-testing/europe-west1/getProxyCertificate";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
  },
  modules: {
    data,
    scan,
    settings,
    user,
    popup,
    upload,
    scale,
    count,
    headCount,
    autoCount,
    dispatchToAbattoir,
    groupWeigh,
    power,
    selection,
    certificates,
    sync
  },
  plugins: [createPersistedState()]
});
