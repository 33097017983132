























































import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import AppSettings from "@/app-settings";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Settings extends Vue {
  hardwareServerAddress: string = "";
  hardwareServerRestfulPort: string = "";
  hardwareServerReaderPort: string = "";
  onsiteServerAddress: string = "";
  onsiteServerRestfulPort: string = "";
  lowPowerLevel: number = 0;
  highPowerLevel: number = 0;
  uiMode: "Light" | "Dark" = "Light";

  saveButtonState: ButtonState = "ready";
  powerLevelSlider = 100;

  isOrca: boolean = false;

  @Watch("isOrca")
  onChangeIsOrca(val: any) {
    this.$store.commit("isOrca", val);
  }

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  mounted() {
    this.isOrca = this.$store.state.isOrca;

    this.hardwareServerAddress = this.appSettings.hardwareServerAddress;
    this.hardwareServerRestfulPort = this.appSettings.hardwareServerRestfulPort;
    this.hardwareServerReaderPort = this.appSettings.hardwareServerReaderPort;
    this.onsiteServerAddress = this.appSettings.onsiteServerAddress;
    this.onsiteServerRestfulPort = this.appSettings.onsiteServerRestfulPort;
    this.lowPowerLevel = this.getPowerInPercentage(this.$store.state.power.lowPowerLevel);
    this.highPowerLevel = this.getPowerInPercentage(this.$store.state.power.highPowerLevel);
    this.uiMode = this.appSettings.isDarkMode ? "Dark" : "Light";
  }

  save() {
    console.log("save settings");

    //console.log(this.hardwareServerAddress);

    let appSettings: AppSettings = new AppSettings();
    appSettings.hardwareServerAddress = this.hardwareServerAddress;
    appSettings.hardwareServerRestfulPort = this.hardwareServerRestfulPort;
    appSettings.hardwareServerReaderPort = this.hardwareServerReaderPort;
    appSettings.onsiteServerAddress = this.onsiteServerAddress;
    appSettings.onsiteServerRestfulPort = this.onsiteServerRestfulPort;

    appSettings.hardwareServerSecureProtocol = true;
    appSettings.onsiteServerSecureProtocol = true;
    this.$store.commit("power/setLowPowerLevel", this.getPowerinDB(this.lowPowerLevel));
    this.$store.commit("power/setHighPowerLevel", this.getPowerinDB(this.highPowerLevel));

    appSettings.isDarkMode = this.uiMode === "Dark" ? true : false;

    this.$store.commit("settings/setAppSettings", appSettings);

    this.$vuetify.theme.dark = this.$store.state.settings.appSettings.isDarkMode;

    this.saveButtonState = "success";
  }

  getPowerinDB(power: number) {
    return Math.round(10 * Math.log10(power * 20));
  }

  getPowerInPercentage(percent:number) {
    return Math.floor(Math.pow(10,percent/10)) / 20;
  }

  get appSettings() {
    return this.$store.state.settings.appSettings;
  }
}
