import { default as Vuex, Module, ActionContext } from "vuex";
import { formatDateToSqlDateTime } from "@/helpers/date-time";
import { restApi } from "@/rest-api";
import * as DataModels from "@gigalot/data-models";
import lodash from "lodash";
import axios from "axios";
import router from "@/router";
import AppSettings from "@/app-settings";

export type AppMode =
  | "count" //roll call
  | "count-without-save"
  | "group-weigh"
  | "dispatch-to-abattoir"
  | "hospital"
  | "head-count";

export type AppState =
  | "menu"
  | "connecting"
  | "connected" //connected and scanning
  | "stopping_before_counting"
  | "stopped_before_counting"
  | "counting" //tags were cleared so count button was enabled and here we are
  | "stopping_counting"
  | "stopped_counting" //stopped from counting, can save or resume from here (could also clear)
  | "resuming_counting"
  | "saving"
  | "saved";

class ScanState {
  [key: string]: any;
  webSocket?: WebSocket;
  appState?: AppState = "menu";
  appMode?: AppMode; //TODO: block users from changing mode if tags are present. Tags must first be saved or cleared from previous operation.
  tags: DataModels.Tag[] = [];
  mapSgtin: { [key: string]: number; } = {};
  countButtonBackoff: boolean = false;
  error: boolean = false;
  sgln?: string;
  statusMessage?: string; //TODO: change this to error message, show this when a state has an error
  countButtonBackoffTime: number = 2500; //milliseconds
}

let countButtonBackoffDebounced: any = null;

class ScanModule implements Module<ScanState, any> {
  namespaced = true;
  state: ScanState = new ScanState();
  mutations = {
    updateSgln(state: ScanState, payload: string) {
      state.sgln = payload;
    },
    setWebSocket(state: ScanState, payload: WebSocket) {
      state.webSocket = payload;
    },
    setAppState(state: ScanState, payload: AppState) {
      state.error = false;
      state.appState = payload;
    },
    addTags(state: ScanState, payload: string[]) {
      payload.forEach(sgtin => {
        if (state.mapSgtin[sgtin] === undefined) {
          state.mapSgtin[sgtin] = 1;

          state.tags.push(new DataModels.Tag(formatDateToSqlDateTime(), sgtin, undefined));
        } else {
          state.mapSgtin[sgtin]++;
        }
      });
    },
    clearTags(state: ScanState) {
      state.tags = [];
      state.mapSgtin = {};
    },
    setCountButtonBackoff(state: ScanState, payload: boolean) {
      state.countButtonBackoff = payload;
    },
    flagError(state: ScanState) {
      state.error = true;
      //TODO: consider setting error message here when error is flagged
    },
    clearError(state: ScanState) {
      state.error = false;
      //TODO: clear error message
    },
    setStatusMessage(state: ScanState, payload: string) {
      //TODO: change to error message
      state.statusMessage = payload;
    },
    //setAppMode assumes that the app mode was allowed to change because previous app mode is saved or has no tags
    setAppMode(state: ScanState, payload: AppMode) {
      if (state.appMode !== payload) {
        //if app mode is different then clean up app state (clear errors and set to menu)
        state.appState = "menu";
        state.error = false;
      }
      state.appMode = payload;
    }
  };
  actions = {
    onAppCreated(context: ActionContext<ScanState, any>) {
      console.log("scan/onAppCreated");
      context.commit("setWebSocket", null);
      if (
        //clear state if app wasn't counting before last close
        context.state.appState === null ||
        context.state.appState === "menu" ||
        context.state.appState === "connecting" ||
        context.state.appState === "stopped_before_counting" ||
        context.state.appState === "connected" ||
        context.state.appState === "stopping_before_counting"
      ) {
        context.commit("setAppState", "menu");
        context.commit("clearTags");
        context.commit("updateSgln", null);
      } else if (
        //clear state if app was counting before last close but there were no tags scanned
        context.state.tags.length == 0 &&
        (context.state.appState === "counting" ||
          context.state.appState === "resuming_counting" ||
          context.state.appState === "stopping_counting" ||
          context.state.appState === "stopped_counting")
      ) {
        //attempt to stop scanner if state was counting, resuming_counting, or stopping_counting because the scanner could still be running
        if (context.state.appState !== "stopped_counting") {
          let address = context.rootState.settings.appSettings.hardwareServerAddress;
          let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
          let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
          let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryStop}`;

          //TODO: use fetch instead of axios
          axios.get(url, {
            timeout: 10000
          });
        }
        context.commit("setAppState", "menu");
        context.commit("clearTags");
        context.commit("updateSgln", null);
      } else if (
        //if app was busy counting and had tags then attempt to stop scanner and set state to stopped_counting
        context.state.tags.length > 0 &&
        (context.state.appState === "counting" || context.state.appState === "resuming_counting" || context.state.appState === "stopping_counting")
      ) {
        let address = context.rootState.settings.appSettings.hardwareServerAddress;
        let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
        let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
        let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryStop}`;

        //TODO: use fetch instead of axios
        axios.get(url, {
          timeout: 10000
        });

        context.commit("setAppState", "stopped_counting");
      }
      // else if (context.state.appState === "saving") {
      //   context.dispatch("saveResults");
      // }
    },
    async connect(context: ActionContext<ScanState, any>, o: { jumpToCountingState: boolean; }) {
      o = o || { jumpToCountingState: false };

      //When scanning for counting or moving animals (dispatch to kraal), we don't need to check if the area is all cleared.
      //The user can still clear the tags though, they'll just be asked to confirm the clearing.
      let appMode: AppMode | undefined = context.state.appMode;
      //o.jumpToCountingState = appMode === "count" || appMode === "count-without-save";
      o.jumpToCountingState = true; //skip the checking tag phase for all modes, it's too annoying in use.

      if (o.jumpToCountingState) {
        context.commit("setAppState", "resuming_counting");
      } else {
        context.commit("setAppState", "connecting");
      }

      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let readerPort = context.rootState.settings.appSettings.hardwareServerReaderPort;

      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);

      //let sglnUrl = `${protocol}://${address}:${restfulPort}${restApi.apiRfidSgln}`;

      if (context.rootState.isOrca) {
        context.commit("setStatusMessage", "Orca detected. Opening web socket.");
        o.jumpToCountingState = true;
      } else {
        // commenting out this code for now, we don't really use this
        // context.commit("setStatusMessage", "Connecting...");
        // try {
        //   //TODO: use fetch instead of axios
        //   let response = await axios.get(sglnUrl, {
        //     timeout: 10000
        //   });

        //   context.commit("updateSgln", response.data);
        // } catch (error) {
        //   context.commit("setStatusMessage", "Failed to connect: Failed to obtain SGLN. Ensure wifi is connected to GigaLOT Gantry");
        //   context.commit("flagError");
        //   return;
        // }
        context.commit("setStatusMessage", "Connecting...");
      }

      try {
        //create WebSocket
        //let webSocket: WebSocket =
        //commit("setWebSocket", webSocket)
        let wsProtocol = AppSettings.getHardwareWebSocketProtocol(context.rootState.settings.appSettings);
        let webSocket: WebSocket = new WebSocket(`${wsProtocol}://${address}:${readerPort}`);
        webSocket.onopen = async event => {
          if (context.rootState.isOrca) {
            context.commit("setStatusMessage", "Web Socket opened.");
            context.commit("setAppState", "counting");
          } else {
            context.commit("setStatusMessage", "Web Socket opened. Starting Reader.");
            //start reader
            try {
              let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryStart}`;
              //TODO: use fetch instead of axios
              let response = await axios.get(url, { timeout: 10000 });
              if (response.data === false) {
                context.commit("setStatusMessage", "Reader connected but reader failed to start.");
                return;
              }

              if (o.jumpToCountingState) {
                context.commit("setAppState", "counting");
              } else {
                context.commit("setCountButtonBackoff", true);
                if (countButtonBackoffDebounced) {
                  //cancel timer if it exists otherwise it can interfere and enable Count too soon
                  countButtonBackoffDebounced.cancel();
                }
                countButtonBackoffDebounced = lodash.debounce(() => {
                  context.commit("setCountButtonBackoff", false);
                }, context.state.countButtonBackoffTime);
                countButtonBackoffDebounced();
                context.commit("setAppState", "connected");
              }
              context.commit(
                "setStatusMessage",
                //"Web Socket opened. Starting Reader.",
                "Scanning..."
              );
            } catch (error) {
              context.commit("setStatusMessage", "Failed to connect: Error starting reader.");
              context.commit("flagError");
            }
          }
        };
        webSocket.onerror = event => {
          context.commit("setStatusMessage", "Failed to connect: Web socket error.");
          context.commit("flagError");
          console.log("webSocket onerror");
        };
        webSocket.onmessage = message => {
          let sgtins: string[] = message.data
            .split(" ")
            .join("") //remove all spaces that might be in the line with the sgtin
            .split("\n");

          sgtins = sgtins.filter((sgtin: string) => sgtin.length > 0);

          //TODO: regex check all tags sent

          sgtins = sgtins.map((sgtin: string) => {
            if (!sgtin.startsWith("urn:epc:tag:sgtin-96:0.")) {
              sgtin = "urn:epc:tag:sgtin-96:0." + sgtin;
            }
            return sgtin;
          });
          if (["hospital"].includes(router.currentRoute.name ?? "")) {
            context.commit("addTags", [sgtins.filter((value: string) => value.length > 0)[0]]);
          }
          if (["count", "count-without-save", "dispatch-to-abattoir", "group-weigh", "head-count"].includes(router.currentRoute.name ?? "")) {
            context.commit(
              "addTags",
              sgtins.filter((value: string) => value.length > 0)
            );
          }
        };
        webSocket.onclose = event => {
          console.log("webSocket onclose");
        };
        context.commit("setWebSocket", webSocket);
      } catch (error) {
        //move this catch rather to webSocket.onerror
        context.commit("setStatusMessage", "Failed to connect: Failed to open web socket");
        return;
      }

      //get sgln from pi
      //if success then open websocket to reader
      //if success then start reader
      //if success then set timer for 2 seconds
      //go to scan view
    },
    disconnect(context: ActionContext<ScanState, any>) {
      if (context.state.webSocket) context.state.webSocket.close();
    },
    async startCount(context: ActionContext<ScanState, any>) {
      // let address =
      //   context.rootState.settings.appSettings.hardwareServerAddress;
      // let restfulPort =
      //   context.rootState.settings.appSettings
      //     .hardwareServerRestfulPort;
      // let url = `https://${address}:${restfulPort}${restApi.apiGantryStart}`;
      context.commit("clearError");

      //if state is "connected" then set state to "counting" (count button is disabled so long as tags were found in "connected" state)
      if (context.state.appState === "connected") {
        context.commit("setAppState", "counting");
      }

      if (context.state.appState === "stopped_counting") {
        context.dispatch("connect", { jumpToCountingState: true });
      }

      //if state is "stopped_before_counting" then connect reader and set state to "connecting"
      if (context.state.appState === "stopped_before_counting") {
        context.dispatch("connect");
      }

      if (context.state.appState === "resuming_counting" && context.state.error) {
        context.dispatch("connect", { jumpToCountingState: true });
      }
    },
    async stopCount(context: ActionContext<ScanState, any>) {
      if (countButtonBackoffDebounced) {
        countButtonBackoffDebounced.flush();
      }

      if (context.state.appState === "connected" || context.state.tags.length === 0) {
        context.commit("setAppState", "stopping_before_counting");
      } else if (context.state.appState === "counting") {
        context.commit("setAppState", "stopping_counting");
      }

      context.commit("setStatusMessage", "Stopping scan...");

      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryStop}`;

      try {
        //TODO: use fetch instead of axios
        let response = await axios.get(url, {
          timeout: 10000
        });

        if (context.state.appState === "stopping_before_counting") {
          context.commit("setAppState", "stopped_before_counting");
        } else if (context.state.appState === "stopping_counting") {
          context.commit("setAppState", "stopped_counting");
        }

        context.commit("setStatusMessage", "Scanning stopped.");
      } catch (error) {
        context.commit("setStatusMessage", "Failed to stop scan.");
        context.commit("flagError");
      }
    },
    async sendStopCommand(context: ActionContext<ScanState, any>) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryStop}`;
      //TODO: use fetch instead of axios
      axios.get(url);
    },
    //singleScan just calls HSL for a single scan, it does not follow the state that the rest of the module.
    async singleScan(context: ActionContext<ScanState, any>) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}/HardwareServiceLayer/api/Rfid/sgtin`;
      let s: string;

      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(`response not ok ${response.status} ${response.statusText}`);
      }
      s = await response.text();
      console.log(`singleScan: ${s}`);

      if (s.startsWith('"')) s = s.slice(1);
      if (s.endsWith('"')) s = s.slice(0, -1);

      console.log(`singleScan after removing surrounding "s: ${s}`);

      if (s.startsWith("ERROR") || s.startsWith('"ERROR')) {
        throw Error(s);
      }

      return s; //sgtin

    }
  };
  getters = {
    getScanStatusMessage(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      switch (state.appState) {
        case "menu": {
          return "Ready.";
        }
        case "connecting": {
          return state.error ? "Error connecting" : "Connecting...";
        }
        case "connected": {
          return "Connected and checking for tags...";
        }
        case "stopping_before_counting": {
          return state.error ? "Error stopping scan" : "Stopping scan...";
        }
        case "stopped_before_counting": {
          return "Scanning stopped before counting was started";
        }
        case "counting": {
          return "Counting tags";
        }
        case "stopping_counting": {
          return state.error ? "Error stopping counting" : "Stopping counting";
        }
        case "stopped_counting": {
          return "Counting stopped";
        }
        case "resuming_counting": {
          return state.error ? "Error resuming counting" : "Resuming counting";
        }
        case "saving": {
          return state.error ? "Error saving" : "Saving";
        }
        case "saved": {
          return "Saved";
        }
        default: {
          return "";
        }
      }
    },
    isAppModeAllowedToContinue(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //If the app is still busy with a different application of the gantry then changing the app mode should not be allowed.
      //Gantry application must first be cleared or saved before a different app mode can be used.

      //return state.isUserLoggedIn && state.userRole;
      return function (appMode: AppMode) {
        if (state.appMode === appMode) {
          return true;
        }

        if (state.appMode !== appMode && state.appState === "saved") {
          return true;
        }

        if (state.appMode !== appMode && state.tags.length === 0) {
          return true;
        }

        return false;
        //console.log("isAppModeAllowedToContinue called with " + appMode);
        //console.log("isAppModeAllowedToContinue called with " + appMode);
      };
    },
    //Returns true if scanner is busy checking for nearby tags before counting
    isCheckingForTags(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      let appState: AppState = state.appState ? state.appState : "menu";
      switch (appState) {
        case "menu":
        case "connecting":
        case "connected":
        case "stopping_before_counting":
        case "stopped_before_counting":
          return true;
        case "counting":
        case "stopping_counting":
        case "stopped_counting":
        case "resuming_counting":
        case "saving":
        case "saved":
          return false;
      }
    }
  };
}

const scanModule: ScanModule = new ScanModule();

export default scanModule;
