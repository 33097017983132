import * as DataModels from "@gigalot/data-models";

export default class Uploadable {
  uploaded?: boolean = false;
}

export type UploadableModel = DataModels.UpstreamModel & Uploadable;

export type UploadableScanningResult = DataModels.ScanningResult & Uploadable;

export type HospitalResult = DataModels.HospitalResult & Uploadable;
