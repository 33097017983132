


















































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Scan from "@/components/Scan.vue";
import { sgtinVisualPart } from "@/helpers/sgtin-ui-friendly-tag";
import { withdrawalDaysFromToday } from "@/helpers/withdrawal";
import { sortTags } from "@/helpers/sort-tags";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";

@Component({
  components: {
    Scan,
  },
})
export default class Count extends Vue {
  batchNumber: string = "";
  batchQuantity: string = "";
  notes: string = "";

  sortTags = sortTags;

  tagUi(tag: Models.Tag): string {
    let ret = this.sgtinVisualPart(tag.sgtin);
    const animal = this.animals.find((a) => a.sgtin === tag.sgtin);
    if (animal) {
      if (animal.owner) ret += ` - ${animal.owner}`;
      if (animal.withdrawalDays !== undefined && animal.withdrawalDays > 0) ret += ` (${animal.withdrawalDays})`;
      if (animal.producer) ret += ` - ${animal.producer}`;
      if (animal.allflex?.startsWith("LA.516")) ret += ` - NAM`;
      if (animal.allflex?.startsWith("LA.072")) ret += ` - BWA`;
    }
    return ret;
  }

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  sgtinVisualPart(sgtin: string) {
    try {
      return sgtinVisualPart(sgtin);
    } catch (err) {
      console.error(err);
      return "";
    }
  }

  onClearWhenSaved() {
    this.batchNumber = "";
    this.batchQuantity = "";
    this.$store.commit("dispatchToAbattoir/batchQuantity", "");
    this.$store.commit("dispatchToAbattoir/batchNumber", "");
  }

  sgtins: string[] = [];
  animals: (Models.Animal & { withdrawalDays?: number })[] = [];

  get tags() {
    const tags: Models.Tag[] = this.$store.state.scan.tags;
    this.sgtins = tags.map((t) => t.sgtin);
    return this.$store.state.scan.tags;
  }

  @Watch("sgtins")
  onSgtinsChanged(val: string[], oldVal: string[]) {
    const newSgtins = lodash.difference(val, oldVal); //only lookup new sgtins, otherwise it seems that the system looks up too many of the same animals
    const addAnimal = async (sgtin: string) => {
      const animal = await this.$store.dispatch("data/getAnimal", sgtin);
      if (animal) {
        animal.withdrawalDays = withdrawalDaysFromToday(animal);
        this.animals.push(animal);
      }
    };
    for (const sgtin of newSgtins) addAnimal(sgtin);

    //filter out any animals with sgtin no longer being used
    this.animals = this.animals.filter((a) => this.sgtins.find((sgtin) => a.sgtin === sgtin) !== undefined);
  }

  @Watch("batchNumber")
  async onBatchNumberChanged(val: any, oldVal: any) {
    this.$store.commit("dispatchToAbattoir/batchNumber", val);
  }

  @Watch("batchQuantity")
  async onBatchQuantityChanged(val: any, oldVal: any) {
    this.$store.commit("dispatchToAbattoir/batchQuantity", parseInt(val));
  }

  @Watch("notes")
  async onNotesChanged(val: any, oldVal: any) {
    this.$store.commit("dispatchToAbattoir/notes", val);
  }

  async mounted() {
    this.$store.commit("scan/setAppMode", "dispatch-to-abattoir");

    if (this.$store.state.scan.appState === "saving") {
      this.$store.commit("scan/flagError");
    }
    this.$store.dispatch("power/setPowerMode", "high");

    //await this.$store.dispatch("data/linkSgtinsToAnimalsFromDb", this.tags);

    if (this.tags.length > 0) {
      this.batchQuantity = this.$store.state.dispatchToAbattoir.batchQuantity;
      this.batchNumber = this.$store.state.dispatchToAbattoir.batchNumber;
    } else {
      this.$store.commit("dispatchToAbattoir/batchQuantity", "");
      this.$store.commit("dispatchToAbattoir/batchNumber", "");
      this.$store.commit("dispatchToAbattoir/notes", "");
    }
  }

  async onSave() {
    await this.$store.dispatch("dispatchToAbattoir/save");
  }
}
