



































































import { Component, Vue, Prop } from "vue-property-decorator";
import EarTag from "@/components/shared/EarTag.vue";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import * as Models from "@gigalot/data-models";
import { getTagColour, sgtinUiFriendlyTag, sgtinVisualPart } from "@/helpers/sgtin-ui-friendly-tag";
import { capitalize } from "@/helpers";
import moment from "moment";

@Component({
  components: { EarTag, AnimalEventHistory },
})
export default class AnimalData extends Vue {
  @Prop() animal!: Models.Animal;

  tab = 0;

  created() {
    if (!this.animal) this.$router.push({ name: "home" }); //If they refresh page just take them to home page
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  get tagColour() {
    return getTagColour(this.animal.sgtin);
  }

  get sgtinVisualPart() {
    return this.animal ? sgtinVisualPart(this.animal.sgtin) : "";
  }

  get firstProcessedTime() {
    return this.animal.events[this.animal.events.length - 1].time as number;
  }

  get sgtinUiFriendlyTag() {
    return this.animal ? sgtinUiFriendlyTag(this.animal.sgtin) : "";
  }

  get standingDays() {
    return moment().diff(moment(this.firstProcessedTime), "days");
  }

  get gender() {
    return capitalize(this.animal?.gender);
  }

  get moment() {
    return moment;
  }

  get totalAdg() {
    const processingEvents = this.animal.events.filter((e) => e.type.startsWith("processing_"));
    if (processingEvents.length < 2) return "";
    const firstEvent = processingEvents[processingEvents.length - 1];
    const lastEvent = processingEvents[0];
    if (firstEvent.mass === undefined || lastEvent.mass == undefined) return "";
    if (firstEvent.time === undefined || lastEvent.time == undefined) return "";
    const massDiff = lastEvent.mass - firstEvent.mass;
    const daysBetween = moment(lastEvent.time).diff(moment(firstEvent.time), "days");
    if (daysBetween === 0) return "";
    return `${(massDiff / daysBetween).toFixed(2)} kg/day`;
  }

  gainBetweenEvents(event: Models.AnimalEvent, index: number) {
    if (index >= this.animal.events.length) return "N/A";
    let previousEvent = this.animal.events[index + 1];
    if (event.mass === undefined || previousEvent.mass === undefined) return "N/A";
    return event.mass - previousEvent.mass;
  }

  daysBetweenEvents(event: Models.AnimalEvent, index: number) {
    if (index >= this.processingEvents.length) return "N/A";
    let previousEvent = this.processingEvents[index + 1];
    return moment(event.time).diff(moment(previousEvent.time), "days");
  }

  get processingEvents() {
    // sort order: more recent events come first
    const processedEvents = this.animal.events.filter((e) => e.type.startsWith("processing_"));
    for (let i = 0; i < processedEvents.length - 1; ++i) {
      const curr = processedEvents[i];
      const prev = processedEvents[i + 1];
      if (curr.mass === undefined || prev.mass === undefined || curr.time === undefined || prev.time === undefined) {
        curr.adg = undefined;
        curr.massDiff = undefined;
      } else {
        const massDiff = curr.mass - prev.mass;
        const daysBetween = moment(curr.time).diff(moment(prev.time), "days");
        if (daysBetween === 0) {
          curr.adg = undefined;
          curr.massDiff = undefined;
        } else {
          curr.massDiff = massDiff;
          curr.adg = massDiff / daysBetween;
        }
      }
    }
    return processedEvents;
  }

  get hospitalEvents() {
    return this.animal.events.filter((e) => e.type === "hospital");
  }
}
