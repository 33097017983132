
























































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import Scan from "@/components/Scan.vue";
import { sortTags } from "@/helpers/sort-tags";
import * as Models from "@gigalot/data-models";
import { ScanningResult } from "@gigalot/data-models";
import { convertAutoscanToBatch } from "../../helpers/scan-converter";
import { v4 } from "uuid";

@Component({
  components: {
    Scan,
    DimssaButton,
  },
})
export default class AutoCount extends Vue {
  powerLevel?: number;
  autoUpload: boolean = false;
  searchInput: string = "";
  kraalIds: string[] = [];
  selectedTag = {};
  kraalId: string = ""; //kraalId autocomplete text field model
  kraal2Id: string = "";
  batchNumber: string = "";
  batchQuantity: string = "";
  notes: string = "";
  settingDialog = false;

  powerLevelSlider = 100;
  scanIntervalSlider = 0;
  scannedBatches: ScanningResult[] = [];
  deviceScanning = false;
  selectedBatches: any[]= [];
  batchGuid = "";
  geolocation = { lat: 0, lon: 0 };
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  sortTags = sortTags;

  animalTableHeader = [
    {
      text: "Tag",
      value: "sgtin",
      divider: true,
      width: 90,
    },
    {
      text: "Scanned Time",
      value: "ScannedTime",
      divider: true,
      width: 90,
    },
    {
      text: "Antenna",
      value: "scannedAntennae",
      divider: true,
      width: 90,
    },
  ];

  onClearWhenSaved() {
    this.batchNumber = "";
    this.batchQuantity = "";
    this.notes = "";
    this.$store.commit("autoCount/batchQuantity", "");
    this.$store.commit("autoCount/batchNumber", "");
    this.kraalId = "";
    this.$store.commit("autoCount/kraalId", "");
    this.kraal2Id = "";
    this.$store.commit("autoCount/kraal2Id", "");
  }

  async onCount() {
    if (this.batchNumber === "") {
      //popup error
       this.$store.commit("popup/displayOk", `Please enter a batch reference.`);
      return;
    }
    if (this.kraalId === "") {
      //popup error
       this.$store.commit("popup/displayOk", `Please select a Kraal`);
      return;
    }
    this.$store.dispatch("power/setPowerLevel", this.powerinDB);
    this.settingDialog = false;
    //Set power level
    //generate batch guid
    let val = await this.isDeviceScanning();
    if (!val) this.batchGuid = v4();
    else {
      //show error message device already scanning.
      this.$store.commit("popup/displayOk", `Scanner is already running. Please stop scan before starting a new one.`);
      return;
    }
    return this.onAutoCountStart(
      this.batchNumber ?? "TODO: AutoBatchGeneratedNumber",
      this.autoUpload ?? false,
      this.scanIntervalSlider ?? 0,
      this.kraalId,
      this.kraal2Id,
      this.batchGuid,
      this.geolocation.lat.toString(),
      this.geolocation.lon.toString()
    );
  }
  get powerinDB(){
    return Math.round(10 * Math.log10(this.powerLevelSlider * 20));
  }
  get antennaCount(){ 
    return this.$store.state.autoCount.antennaCount;
  }
  onStop() {
    return this.onAutoStop();
  }
  async isDeviceScanning() {
    let response = await this.$store.dispatch("autoCount/isDeviceScanning");
    console.log(`Device scanning: ${JSON.stringify(response.data)}`);
    this.deviceScanning = response.data;
    return response.data;
  }

  async onAutoCountStart(batch: string, autoUpload: boolean, readInterval: number, kraal: string, kraal2: string, batchGuid: string, lat: string, lon: string) {
    await this.$store.dispatch("autoCount/startAutoCount", { batch, autoUpload, readInterval, kraal, kraal2, batchGuid, lat, lon });
    await this.isDeviceScanning();
  }

  async getGeolocation() {
    let location = await this.$store.dispatch("autoCount/getGeolocation");
    console.log(location);
    this.geolocation = location;
  }

  async onAutoStop() {
    await this.$store.dispatch("autoCount/stopAutoCount");
    await this.isDeviceScanning();
  }
  searchFilter(s: string) {
    return s.toLowerCase().startsWith(this.searchInput.toLowerCase());
  }

  //used for autocomplete
  get searchItems() {
    return this.kraalIds;
  }

  get DeviceScanning() {
    return this.deviceScanning;
  }

  get DeviceConnected() {
    return this.$store.getters["autoCount/getConnectionState"];
  }
  isIgnored(batchId: string) {
    //console.log(`Debug : ${this.$store.getters["autoCount/getIgnoreGuids"]}`);
    return this.$store.getters["autoCount/getIgnoreGuids"].includes(batchId); //this.ignoreGuids.includes(batchId);
  }

  confirmDeleteBatch(batchNumber:string, batchId: string) {
    this.$store.commit("popup/displayYesNo", {
      message: `Do you want to ignore batch ${batchNumber} on this device?`,
      yesAction: async () => {
        //Remove batch
        this.$store.commit("autoCount/addIgnoreGuid", batchId);
       // this.ignoreGuids.push(batchId);
       // console.log(this.ignoreGuids.length);
        this.getBatch();
      },
    });
  }

  async getBatch() {
    let scannedTags = await this.$store.dispatch("autoCount/getBatchDetails");
    let upstreamMetadata = this.$store.getters["upload/getUpstreamMetadata"]();
    let convertedScanResults: Models.ScanningResult[] = convertAutoscanToBatch(scannedTags, upstreamMetadata);
    if (convertedScanResults) {
      //remove ignored batches
      this.scannedBatches = convertedScanResults;
      //console.dir(convertedScanResults);
    }
  }

  @Watch("selectedBatches")
  onBatchSelectionChanged(selectedBatches: any) {
    //console.log(selectedBatches);
    if (selectedBatches?.length > 0) {
      this.$store.commit("navFuncs", { save: this.save, back: this.back });
    } else {
      this.$store.commit("navFuncs", { save: undefined, back: this.back });
    }
  }

  back() {
    this.$router.go(-1);
  }


  destroyed() {
    this.$store.commit("autoCount/disconnect");
  }

  async mounted() {
    this.$store.dispatch("autoCount/getAntennaCount");
    this.$store.dispatch("autoCount/connect");
    this.getGeolocation();
    this.kraalIds = await this.$store.dispatch("data/getKraalIds");
    this.$store.dispatch("power/setPowerMode", "high");
    this.getBatch();
    this.isDeviceScanning();
  }

  async refreshBatch() {
    await this.getBatch();
    await this.isDeviceScanning();
    this.selectedBatches = [];
  }

  async save() {
    if (this.selectedBatches) {
      await this.$store.dispatch("autoCount/save", this.selectedBatches);
      for (let i=0;i<this.selectedBatches.length;i++){
        this.$store.commit("autoCount/addIgnoreGuid", this.selectedBatches[i].guid);
      }
    }
  }
}
