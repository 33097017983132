// import * as vuex from "vuex";
// import AppSettings from "@/app-settings";
// import { restApi } from "@/rest-api";

import lodash from "lodash";
import { ActionContext } from "vuex";
import { UploadState } from "@/store/modules/upload";
import { UploadableModel, UploadableScanningResult } from "@/models/uploadable";
import { restApi } from "@/rest-api";
import { HospitalResult } from "../models/uploadable";

async function uploadData(data: any, url: string, jwt: string, context: ActionContext<UploadState, any>): Promise<Response> {
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: process.env.NODE_ENV === "production" ? "include" : undefined, //TODO: should we also disable credentials when using http?
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt
    }),
    body: JSON.stringify(data)
  };

  let response = await fetch(url, options);

  return response;
}

async function setUploaded(guid: string, getAction: string, setAction: string, context: ActionContext<UploadState, any>) {
  let savedData = await context.dispatch(getAction, guid, { root: true });
  savedData.uploaded = true;
  await context.dispatch(setAction, savedData, { root: true });
}

async function uploadModelGql(
  model: UploadableModel,
  variableName: string,
  getAction: string,
  setAction: string,
  destination: string,
  context: ActionContext<UploadState, any>,
  gql: string
) {
  let guid = model.guid;

  let toUpload = lodash.cloneDeep(model);
  delete toUpload.uploaded;
  const locationGuid: any = context.rootState.user.location?.guid;
  if (!locationGuid) throw Error("uploadModelGql error: No location guid found!");
  let variables = { [variableName]: toUpload, guid: locationGuid };
  try {
    console.log("Attempting to upload model with gql.");
    let jwt = await context.dispatch("user/getOfflineIdToken", undefined, { root: true });
    let response = await context.dispatch("graphQl", { gql, variables, jwt, destination, timeout: 10 * 1000 }, { root: true });
    console.log(response);
    await setUploaded(guid, getAction, setAction, context);
  } catch (err) {
    console.log("Error uploading model with gql.");
    console.log(err);
    throw err;
  }
}

async function uploadScanningResult(scanningResult: UploadableScanningResult, getAction: string, setAction: string, context: ActionContext<UploadState, any>) {
  const destination = "node";

  let gql = `mutation scanningResult($guid: String!, $scanningResult: ScanningResultInput!) {
    scanningResult(guid: $guid, scanningResult: $scanningResult)
  }`;

  await uploadModelGql(scanningResult, "scanningResult", getAction, setAction, destination, context, gql);
}

async function uploadHospital(hospitalResult: HospitalResult, getAction: string, setAction: string, context: ActionContext<UploadState, any>) {
  const destination = "node";

  let gql = `mutation hospitalResult($guid: String!, $hospitalResult: HospitalResultInput!) {
           hospitalResult(guid: $guid, hospitalResult: $hospitalResult)            
           }`;
  await uploadModelGql(hospitalResult, "hospitalResult", getAction, setAction, destination, context, gql);
}

export async function uploadRollCall(rollCall: UploadableScanningResult, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getRollCall";
  let setAction: string = "data/addRollCall";
  await uploadScanningResult(rollCall, getAction, setAction, context);
}

export async function uploadHeadCount(headCount: UploadableScanningResult, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getHeadCount";
  let setAction: string = "data/addHeadCount";
  await uploadScanningResult(headCount, getAction, setAction, context);
}

export async function uploadHospitalResult(hospitalResult: HospitalResult, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getHospitalResult";
  let setAction: string = "data/addHospitalResult";
  await uploadHospital(hospitalResult, getAction, setAction, context);
}

// export async function uploadDispatchToKraal(dispatchToKraal: UploadableScanningResult, context: ActionContext<UploadState, any>) {
//   let getAction: string = "data/getDispatchToKraal";
//   let setAction: string = "data/addDispatchToKraal";
//   await uploadScanningResult(dispatchToKraal, getAction, setAction, context);
// }

export async function uploadDispatchToAbattoir(dispatchToAbattoir: UploadableScanningResult, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getDispatchToAbattoir";
  let setAction: string = "data/addDispatchToAbattoir";
  await uploadScanningResult(dispatchToAbattoir, getAction, setAction, context);
}

export async function uploadGroupWeigh(groupWeigh: UploadableScanningResult, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getGroupWeigh";
  let setAction: string = "data/addGroupWeigh";
  await uploadScanningResult(groupWeigh, getAction, setAction, context);
}
