

























































































































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { AppMode } from "@/store/modules/scan";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Home extends Vue {
  //numYetToUpload: number = 0;

  created() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });

    this.$store.dispatch("upload/getNumYetToUpload");
  }

  buttonState(appMode: AppMode) {
    return this.$store.getters["scan/isAppModeAllowedToContinue"](appMode) ? "ready" : "disabled";
  }

  get dataSyncButtonState(): ButtonState {
    if (this.$store.state.sync.state === "success") return "success";
    return "ready";
  }

  uiFriendlyAppMode(appMode: AppMode) {
    switch (appMode) {
      case "count":
        return "Count";
      case "count-without-save":
        return "Count Without Save";
      case "group-weigh":
        return "Group Weigh";
      case "dispatch-to-abattoir":
        return "Dispatch to Abattoir";
    }
  }

  onClickDisabled(appModeDesired: AppMode) {
    let appModeCurrent = this.$store.state.scan.appMode;
    let message: string;
    if (appModeCurrent === "count-without-save") {
      message = this.uiFriendlyAppMode(appModeDesired) + " disabled. You must first clear the tags in " + this.uiFriendlyAppMode("count-without-save");
    } else {
      message = this.uiFriendlyAppMode(appModeDesired) + " disabled. You must first clear or save the tags in " + this.uiFriendlyAppMode(appModeCurrent);
    }
    this.$store.commit("popup/displayOk", message);
  }

  onClickLogout() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to log out?",
      yesAction: () => {
        this.$store.dispatch("user/signOut");
      },
    });
  }
}
