













import { Component, Vue } from "vue-property-decorator";
import { DimssaButton } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class ViewData extends Vue {
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }
}
