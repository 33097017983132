






















































































































































































































import lodash from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "@/router";
import wb from "./registerServiceWorker";
import VueCookies from "vue-cookies";
import { downloadApkPackage } from "./helpers/download";
import { AppMode } from "@/store/modules/scan";
import AppSettings from "./app-settings";

Vue.use(VueCookies);

declare var navigator: any;
@Component({
  components: {},
})
export default class App extends Vue {
  created() {
    if (wb)
      wb.addEventListener("waiting", async () => {
        //Update is ready, waiting
        this.$store.commit("popup/displayYesNo", {
          message: "An update is available. Would you like to update?",
          yesAction: async () => {
            if (wb) {
              await wb.messageSW({ type: "SKIP_WAITING" });
              window.location.reload();
            } else throw Error("No wb to messageSW SKIP_WAITING");
          },
        });
      });
    //console.log("App created")
    this.$store.dispatch("onAppCreated");
    this.$store.commit("snackbarHide");
  }

  popupKeyDown(event: any) {
    if (event.keyCode === 13) {
      this.$store.commit("popup/hide");
    }
  }

  downloadApkPackage() {
    downloadApkPackage("app-debug");
  }

  buttonState(appMode: AppMode) {
    return this.$store.getters["scan/isAppModeAllowedToContinue"](appMode);
  }

  onFooterClick() {
    //TODO: Refresh hardware server status check
  }

  navigationDrawer: boolean = false;

  isBusyUploading: boolean = false;

  get viewName() {
    console.log(this.$route.name);
    switch (this.$route.name) {
      case "home":
        return "Main Menu";
      case "auto-count":
        return "Auto Counter";
      case "count":
        return "Count";
      case "count-without-save":
        return "Count Without Save";
      case "dispatch-to-abattoir":
        return "Dispatch to Abattoir";
      case "group-weigh":
        return "Group Weigh";
      case "group-weigh/add-mass":
        return "Add Mass";
      case "head-count":
        return "Head Count";
      case "view-data":
        return "View Data";
      case "animal-search":
        return "Animal Search";
      case "animal-data":
        return "Animal Data";
      case "sync":
        return "Data Sync";
      case "outbox":
        return "Outbox";
      case "history":
        return "History";
      case "settings":
        return "Settings";
      case "system":
        return "System";
      case "login":
        return "Login";
      case "hospital-animal-search":
        return "Hospital Animal Search";
      case "hospital":
        return "Hospital";
      case "hospital-treatment":
        return "Treatment";
      case "location":
        return "Location";
      case "selection":
        return `Select ${this.$store.state.selection.itemTypeName}`;
      case "certificates":
        return `Certificates`;
      default: {
        //TODO: consider automating this: remove kebab case and use title capitalization for default case
        throw Error(`viewName: no UI friendly name for route in App.vue: ${this.$router.currentRoute.name}`);
      }
    }
  }

  get statusMessage() {
    return this.$store.getters["scan/getScanStatusMessage"];
  }

  // @Watch("$store.state.scan")
  // async onStatusMessageChanged(val: any, oldVal: any) {
  //   console.log("statusMessage: " + val);
  // }

  beforeMount() {
    const preventNav = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", preventNav);
    this.$once("hook:beforeDestroy", () => window.removeEventListener("beforeunload", preventNav));
  }

  mounted() {
    //console.log("App mounted");

    //this.$store.dispatch("onAppCreated");

    this.$vuetify.theme.dark = this.$store.state.settings.appSettings.isDarkMode;

    if (!navigator.connection.onchange) {
      navigator.connection.onchange = (e: any) => {
        //console.log("navigator.connection.onchange begin");
        if (!this.isBusyUploading) {
          this.isBusyUploading = true;
          /*
          It seems that a network change to no network also counts, so here we'll just wait a while to give the device chance
          to connect to another connection.
          */

          lodash.debounce(async () => {
            console.log("network onchange upload");
            await this.$store.dispatch("upload/upload");
            this.isBusyUploading = false;
          }, 5000)();
        }
        //console.log("navigator.connection.onchange end");
      };

      //Just to clear out a bug we had where the outbox number would go negative
      if (this.$store.state.upload.numYetToUpload < 0) this.$store.commit("upload/numYetToUpload", 0);
    }
  }

  toggleLightDarkMode() {
    const appSettings: AppSettings = this.$store.state.settings.appSettings;
    appSettings.isDarkMode = appSettings.isDarkMode ? false : true;
    this.$store.commit("settings/setAppSettings", appSettings);
    this.$vuetify.theme.dark = this.$store.state.settings.appSettings.isDarkMode;
  }
}
