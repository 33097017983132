import { default as Vuex, Module, ActionContext } from "vuex";

import axios from "axios";

import { restApi } from "@/rest-api";

class PowerState {
  lowPowerLevel: number = 20;
  highPowerLevel: number = 33;
}
class PowerModule implements Module<PowerState, any> {
  namespaced = true;
  state: PowerState = new PowerState();
  mutations = {
    setLowPowerLevel(state: PowerState, powerLevel: number) {
      state.lowPowerLevel = powerLevel;
    },
    setHighPowerLevel(state: PowerState, powerLevel: number) {
      state.highPowerLevel = powerLevel;
    }
  };
  actions = {
    setPowerLevel(context: ActionContext<PowerState, any>, powerLevel: number) { 
      if (context.rootState.isOrca) {
        context.dispatch("setPowerLevelOrca", powerLevel);
      }
      else{
        context.dispatch("setPowerLevelHsl", powerLevel);
      }
    },
    setPowerMode(context: ActionContext<PowerState, any>, mode: string) {
      switch (mode) {
        case "low":
          if (context.rootState.isOrca) {
            context.dispatch("setPowerLevelOrca", context.state.lowPowerLevel);
          } else {
            context.dispatch("setPowerLevelHsl", context.state.lowPowerLevel);
          }

          break;
        case "high":
          if (context.rootState.isOrca) {
            context.dispatch("setPowerLevelOrca", context.state.highPowerLevel);
          } else {
            context.dispatch("setPowerLevelHsl", context.state.highPowerLevel);
          }

          break;
      }
    },
    setPowerLevelOrca(context: ActionContext<PowerState, any>, powerLevel: number) {

      console.log(`Setting power level to ${powerLevel}`);
      let powerLevelSetting = { power: powerLevel };
      console.log("https://" + context.rootState.settings.appSettings.hardwareServerAddress + ":" + context.rootState.settings.appSettings.hardwareServerRestfulPort + "/settings", powerLevelSetting);
      axios
        .post("https://" + context.rootState.settings.appSettings.hardwareServerAddress + ":" + context.rootState.settings.appSettings.hardwareServerRestfulPort + "/settings", powerLevelSetting)
        .then(response => {
          //set power level to response of reader power level
          console.log("response: " + response.data.power);
          //    this.powerLevel = response.data.power;
        })
        .catch(error => {
          console.log("error: " + error);
        });
    },

    setPowerLevelHsl(context: ActionContext<PowerState, any>, powerLevel: number) {
      console.log(`Setting power level to ${powerLevel}`);
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let url = `https://${address}:${restfulPort}${restApi.apiRfidSetPower}`;

      let powerLevelSetting = { power: powerLevel };
      console.log(url, powerLevelSetting);
      axios
        .get(`${url}?powerdB=${powerLevel}`)
        .then(response => {
          //set power level to response of reader power level
          console.log("response: " + response.data.power);
          //    this.powerLevel = response.data.power;
        })
        .catch(error => {
          console.log("error: " + error);
        });

     }
  };
  getters = {
    getPowerLevelHsl(state: PowerState, getters: any, rootState: any, rootGetters: any) { //TODO:
    },
    getPowerLevelOrca(state: PowerState, getters: any, rootState: any, rootGetters: any) {

      axios.defaults.timeout = 10000;
      //lamda function has to be used here, using function() syntax results in a different 'this'
      //TODO: Environment check for http and https
      axios
        .get("https://" + rootState.orcaAddress + ":" + rootState.portHttp + "/settings")
        .then(response => {
          //set power level to response of reader power level
          console.log("response: " + response.data.power);
          //  this.powerLevel = response.data.power;
        })
        .catch(error => {
          console.log("error: " + error);
        });
    }
  };
}

const powerModule: PowerModule = new PowerModule();

export default powerModule;
