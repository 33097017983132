import { default as Vuex, Module, ActionContext } from "vuex";
import lodash from "lodash";

class SelectionState {
  itemTypeName?: string;
  namedItems: { name: string; item: any; }[] = [];
  itemSelected?: (item: any) => void;
}

export default new (class Selection implements Module<SelectionState, any> {
  namespaced = true;
  state: SelectionState = new SelectionState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    itemTypeName(state: SelectionState, itemTypeName?: string) {
      state.itemTypeName = itemTypeName;
    },
    namedItems(state: SelectionState, namedItems: { name: string; item: any; }[]) {
      state.namedItems = namedItems;
    },
    itemSelected(state: SelectionState, itemSelected?: (item: any) => void) {
      state.itemSelected = itemSelected;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
