import { default as Vuex, Module, ActionContext } from "vuex";
//import uploadRollCall from "@/helpers/upload-roll-call";
//import uploadDispatch from '@/helpers/upload-dispatch';
import * as DataModels from "@gigalot/data-models";
import { UploadableScanningResult, UploadableModel, HospitalResult } from "@/models/uploadable";
import { uploadRollCall, uploadDispatchToAbattoir, uploadGroupWeigh, uploadHospitalResult, uploadHeadCount } from "@/helpers/upload-helpers";

export class UploadState {
  isBusyUploading: boolean = false;
  numYetToUpload: number = 0;
  uploadError: boolean = false;
}

class UploadModule implements Module<UploadState, any> {
  namespaced = true;
  state: UploadState = new UploadState();
  mutations = {
    setIsBusyUploading(state: UploadState, payload: boolean) {
      state.isBusyUploading = payload;
    },
    numYetToUpload(state: UploadState, payload: number | "increment" | "decrement") {
      if (payload === "increment") state.numYetToUpload++;
      else if (payload === "decrement") state.numYetToUpload--;
      else state.numYetToUpload = payload;
    },
    uploadError(state: UploadState, payload: boolean) {
      state.uploadError = payload;
    }
  };
  actions = {
    async upload(context: ActionContext<UploadState, any>) {
      //let jwt = this.$store.state.user.accessToken;

      console.log("upload");
      if (context.state.isBusyUploading) {
        console.warn("Already busy uploading.");
        return;
      }
      context.commit("setIsBusyUploading", true);
      try {
        await context.dispatch("uploadRollCalls");
        await context.dispatch("uploadHeadCounts");
        //await context.dispatch("uploadDispatchesToKraals");
        await context.dispatch("uploadDispatchesToAbattoirs");
        await context.dispatch("uploadGroupWeighs");
        await context.dispatch("uploadHospitalResults");
        context.commit("uploadError", false);
      } catch (error) {
        //TODO: error log (maybe add to some messages view)
        context.commit("uploadError", true);
      } finally {
        context.commit("setIsBusyUploading", false);
      }
    },
    async uploadRollCalls(context: ActionContext<UploadState, any>) {
      console.log("uploadRollCalls");
      let rollCalls: UploadableScanningResult[];
      //this.refreshing = true;
      rollCalls = await context.dispatch("data/getRollCalls", null, { root: true });
      let rollCallsToUpload = rollCalls.filter(rollCall => !rollCall.uploaded);

      for (let i = 0; i < rollCallsToUpload.length; ++i) {
        await uploadRollCall(rollCallsToUpload[i], context);
        context.commit("numYetToUpload", "decrement");
      }
    },
    async uploadHeadCounts(context: ActionContext<UploadState, any>) {
      console.log("uploadHeadCounts");
      let headCounts: UploadableScanningResult[];
      //this.refreshing = true;
      headCounts = await context.dispatch("data/getHeadCounts", null, { root: true });
      let headCountsToUpload = headCounts.filter(headCount => !headCount.uploaded);

      for (let i = 0; i < headCountsToUpload.length; ++i) {
        await uploadHeadCount(headCountsToUpload[i], context);
        context.commit("numYetToUpload", "decrement");
      }
    },
    async uploadHospitalResults(context: ActionContext<UploadState, any>) {
      console.log("uploadHospitalResults");
      let hospitalResults: HospitalResult[];
      //this.refreshing = true;
      hospitalResults = await context.dispatch("data/getHospitalResults", null, { root: true });
      let hospitalResultsToUpload = hospitalResults.filter(hospital => !hospital.uploaded);

      for (let i = 0; i < hospitalResultsToUpload.length; ++i) {
        await uploadHospitalResult(hospitalResultsToUpload[i], context);
        context.commit("numYetToUpload", "decrement");
      }
    },
    // async uploadDispatchesToKraals(context: ActionContext<UploadState, any>) {
    //   console.log("uploadDispatchesToKraals");
    //   let dispatchesToKraals: UploadableScanningResult[];
    //   //this.refreshing = true;
    //   dispatchesToKraals = await context.dispatch("data/getDispatchesToKraals", null, { root: true });
    //   let toUpload = dispatchesToKraals.filter(dispatchToKraal => !dispatchToKraal.uploaded);

    //   for (let i = 0; i < toUpload.length; ++i) {
    //     await uploadDispatchToKraal(toUpload[i], context);
    //   }
    // },
    async uploadDispatchesToAbattoirs(context: ActionContext<UploadState, any>) {
      console.log("uploadDispatchesToAbattoirs");
      let dispatchesToAbattoirs: UploadableScanningResult[];
      //this.refreshing = true;
      dispatchesToAbattoirs = await context.dispatch("data/getDispatchesToAbattoirs", null, { root: true });
      let toUpload = dispatchesToAbattoirs.filter(dispatchToKraal => !dispatchToKraal.uploaded);

      for (let i = 0; i < toUpload.length; ++i) {
        await uploadDispatchToAbattoir(toUpload[i], context);
        context.commit("numYetToUpload", "decrement");
      }
    },
    async uploadGroupWeighs(context: ActionContext<UploadState, any>) {
      console.log("uploadGroupWeighs");
      let groupWeighs: UploadableScanningResult[];
      //this.refreshing = true;
      groupWeighs = await context.dispatch("data/getGroupWeighs", null, { root: true });
      let toUpload = groupWeighs.filter(groupWeigh => !groupWeigh.uploaded);

      for (let i = 0; i < toUpload.length; ++i) {
        await uploadGroupWeigh(toUpload[i], context);
        context.commit("numYetToUpload", "decrement");
      }
    },
    async getNumYetToUpload(context: ActionContext<UploadState, any>) {
      let numYetToUpload = 0;
      const dataGetActions = ["getRollCalls", "getGroupWeighs", "getDispatchesToAbattoirs", "getHospitalResults", "getHeadCounts"];
      for (const dataGetAction of dataGetActions) {
        numYetToUpload += (await context.dispatch(`data/${dataGetAction}`, null, { root: true })).filter((i: any) => !i.uploaded).length;
      }
      context.commit("numYetToUpload", numYetToUpload);
      return numYetToUpload;
    }
  };
  getters = {
    getUpstreamMetadata(state: UploadState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        let userName: string = rootState.user.user.displayName;
        let userId: string = rootState.user.user.uid;
        let userEmail: string = rootState.user.user.email;
        let feedlot: string = "";
        let gcp: string = rootState.user.location ? rootState.user.location.gcp : "";
        let location: string = rootState.user.location ? rootState.user.location.guid : "";

        let metadata: DataModels.UpstreamMetadata = new DataModels.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
        return metadata;
      };
    }
  };
}

const upload: UploadModule = new UploadModule();

export default upload;
