





































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class AddMass extends Vue {
  massString: string = "";
  quantityString: string = "";

  quantityOnKeyDown(event: any) {
    //console.log(this.quantityString);
    if (event.keyCode === 13) {
      (this.$refs["mass-input"] as any).focus();
    }
  }

  massOnKeyDown(event: any) {
    //console.log(this.quantityString);
    if (event.keyCode === 13) {
      this.onOk();
    }
  }

  onOk() {
    let mass: number = parseFloat(this.massString);
    let quantity: number = parseInt(this.quantityString);

    if (quantity <= 0) {
      this.$store.commit("popup/displayOk", "Quantity must be greater than 0.");
      return;
    } else if (!quantity) {
      this.$store.commit("popup/displayOk", "Quantity value required.");
      return;
    } else if (mass <= 0) {
      this.$store.commit("popup/displayOk", "Mass must be greater than 0 kg.");
      return;
    } else if (!mass) {
      this.$store.commit("popup/displayOk", "Mass value required.");
      return;
    }

    this.$store.commit("groupWeigh/addMassManual", { mass, quantity });
    this.$router.go(-1);
  }

  get okButtonState(): ButtonState {
    if (!this.quantityString || !this.massString) return "disabled";
    return "ready";
  }

  mounted() {
    //TODO: block entry to this view unless user is coming from add mass button
    (this.$refs["quantity-input"] as any).focus();
  }
}
