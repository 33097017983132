

































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import EarTag from "@/components/shared/EarTag.vue";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { getTagColour, sgtinUiFriendlyTag } from "@/helpers/sgtin-ui-friendly-tag";
import { decimal } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { geolocation } from "@/helpers/geolocation";
import { AnimalListItem } from "@/models/animal-list-item";
import { getAnimalListItems } from "@/helpers/animal-list-persist";

@Component({
  components: {
    AnimalEventHistory,
    DimssaButton,
    EarTag,
  },
  mixins: [validationMixin],
  validations: {
    temperature: { decimal },
    mass: { decimal, positive: (val: any) => val > 0 },
  },
})
export default class Hospital extends Vue {
  hospitalResult: Models.HospitalResult = this.createHospitalResult();

  createHospitalResult() {
    const ret = new Models.HospitalResult(Date.now(), this.$store.getters["upload/getUpstreamMetadata"]());
    ret.morbidity = new Models.Morbidity();
    return ret;
  }

  customFeeder: Models.CustomFeeder | "" = "";
  breed: string = "";
  gender: string = "";

  selectedSourceKraalId: any = "";
  selectedDestinationKraalId: any = "";

  minTemperature = 36.0;
  maxTemperature = 42.0;

  tagColour: string = "green";

  changed: boolean = false;

  tab = 0;

  scanStatusMessage: string = "";

  get processingEvents() {
    if (!this.selectedAnimal) return [];
    return this.selectedAnimal.events.filter((e) => e.type.startsWith("processing_"));
  }

  get hospitalEvents() {
    if (!this.selectedAnimal) return [];
    return this.selectedAnimal.events.filter((e) => e.type === "hospital");
  }

  sgtinUiFriendlyTag = sgtinUiFriendlyTag;

  scanButtonState: ButtonState = "ready";

  async onClickScanTag() {
    try {
      this.scanButtonState = "busy";
      this.scanStatusMessage = "";
      let sgtin = await this.$store.dispatch("scan/singleScan");
      const animal = await this.$store.dispatch("data/getAnimal", sgtin);
      if (animal) {
        this.selectedAnimal = animal;
        console.log(animal);
      } else {
        //animal not found
        throw Error("Animal not found.");
      }
      this.scanButtonState = "success";
    } catch (error) {
      this.scanButtonState = "error";
      this.scanStatusMessage = `${error}`;
    }
  }

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async mounted() {
    this.$store.commit("scan/setAppMode", "hospital");

    if (this.$store.state.scan.appState === "saving") {
      this.$store.commit("scan/flagError");
    }
    // Hospital scan set to use single scan which doesn't require power setting
    this.$store.dispatch("power/setPowerMode", "low");

    //prefill with animal if animal was scanned?
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.hospitalResult = this.createHospitalResult();
        this.$router.go(-1);
      },
    });

    if (!this.hospitalResult.morbidity) {
      this.hospitalResult.morbidity = new Models.Morbidity();
    }

    if (this.hospitalResult.morbidity?.appliedTreatment.length) {
      this.selectedTreatment = this.hospitalResult.morbidity.appliedTreatment[0].treatment;
    }
    this.selectedAilments = this.hospitalResult.morbidity?.ailments || [];
    if (this.hospitalResult.morbidity?.time !== undefined && this.hospitalResult.morbidity?.time !== null) {
      this.dateString = this.moment(this.hospitalResult.morbidity?.time).format("YYYY-MM-DD");
      this.timeString = this.moment(this.hospitalResult.morbidity?.time).format("HH:mm");
    }
    this.massChange(this.hospitalResult.morbidity?.mass);
    this.temperatureChange(this.hospitalResult.morbidity?.temperature);
    if (this.hospitalResult.morbidity?.notes.length) this.notes = this.hospitalResult.morbidity.notes[0];

    this.selectedSourceKraalId = this.hospitalResult.morbidity?.sourceKraalId ?? "";
    this.selectedDestinationKraalId = this.hospitalResult.morbidity?.destinationKraalId ?? "";

    this.determineAppliedDoses();

    this.determineTagColour();

    this.kraalIds = await this.$store.dispatch("data/getKraalIds");

    this.treatments = await this.$store.dispatch("data/getTreatments");

    this.ailments = await this.$store.dispatch("data/getAilments");

    this.animalListItems = await getAnimalListItems();

    this.selectedAnimalListItem = this.hospitalResult.morbidity?.sgtin
      ? this.animalListItems.find((a) => a.sgtin === this.hospitalResult.morbidity?.sgtin)
      : "";
    if (!this.selectedAnimalListItem) this.selectedAnimalListItem = "";
  }

  determineTagColour() {
    this.tagColour = this.hospitalResult?.morbidity?.sgtin ? getTagColour(this.hospitalResult?.morbidity?.sgtin as string)?.name : "green";
  }

  @Watch("selectedSourceKraalId")
  sourceKraalIdChanged(selectedSourceKraalId: any) {
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.sourceKraalId = selectedSourceKraalId;
  }

  @Watch("selectedDestinationKraalId")
  destinationKraalIdChanged(selectedDestinationKraalId: any) {
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.destinationKraalId = selectedDestinationKraalId;
  }

  dateString: string = "";
  dateMenu: boolean = false;

  timeString: string = "";
  timeMenu: boolean = false;

  setDateTime() {
    if (!this.dateString) return;
    if (!this.timeString) return;
    const s = `${this.dateString} ${this.timeString}`;
    console.log(`setting date time ${s} (${this.moment(s).valueOf()})`);
    if (this.hospitalResult?.morbidity) {
      this.hospitalResult.morbidity.time = this.moment(s).valueOf();
    }
    // this.$store.commit("updateUserLocationField", {
    //   path: "hospitalResult.morbidity.time",
    //   value: this.moment(s).valueOf(),
    // });
  }

  // get hospitalResult(): Models.HospitalResult {
  //   if (this.$store.getters["storage"]().hospitalResult === undefined) {
  //     let hospitalResult: Models.HospitalResult = new Models.HospitalResult(Date.now(), this.$store.getters["upload/getUpstreamMetadata"]());
  //     hospitalResult.morbidity = new Models.Morbidity();
  //     this.$store.commit("updateUserLocationField", { path: "hospitalResult", value: hospitalResult });
  //   }
  //   return this.$store.getters["storage"]().hospitalResult;
  // }

  get moment() {
    return this.$store.state.moment;
  }

  rules = {
    validNumber: (value: string) => /^[0-9]*\.{0,1}[0-9]*$/.test(value) || "Invalid input.",
  };

  mass: string = "";
  massChange(mass: any) {
    this.mass = mass ?? "";
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(mass) !== true) {
      if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.mass = undefined;
      return;
    }
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.mass = isNaN(parseFloat(mass)) ? undefined : parseFloat(mass);
    this.determineAppliedDoses();
  }

  temperature: string = "";
  temperatureChange(temperature: any) {
    this.temperature = temperature ?? "";
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(temperature) !== true) {
      if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.temperature = undefined;
      return;
    }
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.temperature = isNaN(parseFloat(temperature)) ? undefined : parseFloat(temperature);
  }

  notes = "";
  notesChange(notes: any) {
    this.notes = notes;
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.notes = [notes];
  }

  doses: Models.Dose[] = [];

  get doseTypes(): { [doseDescription: string]: "cc-mass-dependent" | "constant" } {
    let ret: { [doseDescription: string]: "cc-mass-dependent" | "constant" } = {};
    for (let dose of this.doses) {
      if (dose.item && dose.type) ret[dose.item.description] = dose.type;
      else throw Error("TODO: fix error handling for when dose not found");
    }
    return ret;
  }

  appliedDoses: Models.AppliedDose[] = [];

  get appliedDosesMassDependent() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "cc-mass-dependent");
  }

  get appliedDosesMassConstant() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "constant");
  }

  determineAppliedDoses() {
    //console.log("determineAppliedDoses");

    if (!this.hospitalResult.morbidity?.appliedTreatment.length) return;
    let appliedTreatment = this.hospitalResult.morbidity.appliedTreatment[0];
    let treatment = appliedTreatment.treatment;
    let mass = parseFloat(this.mass);
    if (!treatment) {
      Vue.set(this.hospitalResult.morbidity, "appliedTreatment", []);
      if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.appliedTreatment = [];
      return;
    }

    appliedTreatment.appliedDoses = [];
    if (mass !== undefined && !isNaN(mass)) {
      for (let dose of treatment.doses) {
        let appliedDose = new Models.AppliedDose();
        if (!dose.item) throw Error(`${treatment.description} has a dose that has no dispensary item`);
        appliedDose.item = dose.item;
        appliedDose.unit = dose.unit;
        if (!dose.amount) {
          throw Error(`${treatment.description} has a dose that does not have amount set`);
        }
        if (dose.type === "cc-mass-dependent") {
          if (!dose.kgPerDose) throw Error(`${treatment.description} has a dose that is cc-mass-dependent but does not have kgPerDose set`);
          //TODO: Math.ceil might not work for other feedlots. So far this is good for Sernick. We might need this to be configurable
          appliedDose.actualDose = Math.ceil((mass / dose.kgPerDose) * dose.amount);
        } else {
          appliedDose.actualDose = dose.amount;
        }
        appliedTreatment.appliedDoses.push(appliedDose);
      }
    }

    Vue.set(this.hospitalResult.morbidity, "appliedTreatment", [appliedTreatment]);
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.appliedTreatment = [appliedTreatment];

    if (!this.hospitalResult.morbidity?.appliedTreatment.length) {
      this.doses = [];
      this.appliedDoses = [];
    } else {
      this.doses = this.hospitalResult.morbidity.appliedTreatment[0].treatment.doses;
      this.appliedDoses = this.hospitalResult.morbidity.appliedTreatment[0].appliedDoses;
    }
  }

  onClearWhenSaved() {
    this.clear();
  }

  clear() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to clear?",
      yesAction: async () => {
        this.hospitalResult = new Models.HospitalResult(Date.now(), this.$store.getters["upload/getUpstreamMetadata"]());
        this.hospitalResult.morbidity = new Models.Morbidity();
        this.selectedAnimalListItem = "";
        this.selectedSourceKraalId = "";
        this.selectedDestinationKraalId = "";
        this.dateString = "";
        this.timeString = "";
        this.mass = "";
        this.temperature = "";
        this.selectedAilments = [];
        this.selectedTreatment = "";
        this.appliedDoses = [];
        this.notes = "";
      },
    });
  }
  async onSave() {
    this.save();
  }
  async save() {
    //Data validation before saving
    //Tag ID
    if (!this.hospitalResult.morbidity?.sgtin) {
      this.$store.commit("popup/displayOk", `Can not save. No Tag ID selected.`);
      return;
    }

    //From kraal
    if (!this.hospitalResult.morbidity.sourceKraalId) {
      this.$store.commit("popup/displayOk", `Can not save. No From Kraal selected.`);
      return;
    }

    //To kraal
    if (!this.hospitalResult.morbidity.destinationKraalId) {
      this.$store.commit("popup/displayOk", `Can not save. No To Kraal selected.`);
      return;
    }

    //Date
    if (this.hospitalResult.morbidity.time === undefined) {
      this.$store.commit("popup/displayOk", `Can not save. No Date and time selected.`);
      return;
    }

    //Mass
    if (!this.hospitalResult.morbidity.mass) {
      this.$store.commit("popup/displayOk", `Can not save. No Mass selected.`);
      return;
    }

    //Temperature
    if (this.hospitalResult.morbidity?.temperature !== undefined) {
      const temperature = this.hospitalResult.morbidity?.temperature;
      if (temperature < this.minTemperature || temperature > this.maxTemperature) {
        this.$store.commit("popup/displayOk", `Can not save. Temperature must be between ${this.minTemperature}°C and ${this.maxTemperature}°C.`);
        return;
      }
    }

    //Ailments
    if (!this.hospitalResult.morbidity.ailments.length) {
      this.$store.commit("popup/displayOk", `Can not save. No Ailments selected.`);
      return;
    }

    //Treatment
    if (!this.hospitalResult.morbidity.appliedTreatment.length) {
      this.$store.commit("popup/displayOk", `Can not save. No Treatment selected.`);
      return;
    }

    //TODO: Check if treatment has any mass-dependent doses. If it does then a mass has to be entered

    //TODO: popup and block saving if another mortality with the same sgtin (different guid) is found

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          this.hospitalResult.app = "field";
          let hospitalResultClone: Models.HospitalResult = lodash.cloneDeep(this.hospitalResult);
          delete (hospitalResultClone as any).locationGuid;

          try {
            const p = await geolocation({ enableHighAccuracy: false, maximumAge: 0, timeout: 15 * 1000 });
            if (hospitalResultClone.morbidity) {
              hospitalResultClone.morbidity.gpsLat = p.coords.latitude.toString();
              hospitalResultClone.morbidity.gpsLon = p.coords.longitude.toString();
            }
          } catch (err) {
            console.error("Could not get geolocation: ", err);
          }

          await this.$store.dispatch("data/addHospitalResult", hospitalResultClone, { root: true });
          this.hospitalResult = this.createHospitalResult();
          this.$store.commit("upload/numYetToUpload", "increment", { root: true });
          this.$router.go(-1);
          this.$store.commit("popup/hide");
        } catch (err: any) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err.message}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.hospitalResult = this.createHospitalResult();
        this.$router.go(-1);
      },
    });
  }

  ////////
  // Ailments

  ailments: Models.Ailment[] = [];
  selectedAilments: Models.Ailment[] = [];
  ailmentChange(ailments: any) {
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.ailments = ailments;
  }

  // Ailments
  ////////
  // Animals

  selectedAnimalListItem: any = "";
  selectedAnimal: Models.Animal | "" = "";
  refreshAnimalListItemsErrorMessage: string = "";

  animalListItems: AnimalListItem[] = [];

  @Watch("selectedAnimal")
  selectedAnimalChanged(selectedAnimal: any) {
    //console.log("selectedAnimal: " + JSON.stringify(selectedAnimal));
    let updateRequired = selectedAnimal?.sgtin !== this.hospitalResult.morbidity?.sgtin;
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.sgtin = selectedAnimal?.sgtin;
    if (updateRequired) {
      this.customFeeder = selectedAnimal?.customFeeder ?? "";
      this.breed = selectedAnimal?.breed ?? "";
      this.gender = selectedAnimal?.gender ?? "";

      this.selectedAnimalListItem = this.hospitalResult.morbidity?.sgtin
        ? this.animalListItems.find((a) => a.sgtin === this.hospitalResult.morbidity?.sgtin)
        : "";
      if (!this.selectedAnimalListItem) this.selectedAnimalListItem = "";
    }
    this.determineTagColour();
  }

  @Watch("selectedAnimalListItem")
  async onSelectedAnimalListItemChanged(selectedAnimalListItem: any) {
    console.log("onSelectedAnimalListItemChanged");
    console.dir(selectedAnimalListItem);

    if (selectedAnimalListItem) {
      try {
        this.selectedAnimal = await this.$store.dispatch("data/getAnimal", this.selectedAnimalListItem.sgtin);
      } catch (err: any) {
        console.error("error getting animal from server:", err);
      }
    } else {
      this.selectedAnimal = "";
      await this.$store.commit("scan/clearTags");
    }
  }

  // Animals
  ////////
  // Kraals

  kraalIds: string[] = [];

  // Kraals
  ////////
  // Treatments

  treatments: Models.Treatment[] = [];
  selectedTreatment: Models.Treatment | "" = "";
  treatmentChange(treatment?: Models.Treatment) {
    //console.log("treatmentChange: " + treatment);
    this.selectedTreatment = treatment ?? "";

    let appliedTreatments: Models.AppliedTreatment[] = [];
    if (treatment) {
      appliedTreatments.push(new Models.AppliedTreatment());
      appliedTreatments[0].treatment = treatment;
    }
    if (this.hospitalResult.morbidity) Vue.set(this.hospitalResult.morbidity, "appliedTreatment", appliedTreatments);
    if (this.hospitalResult.morbidity) this.hospitalResult.morbidity.appliedTreatment = appliedTreatments;
    this.determineAppliedDoses();
  }

  // Treatments
  ////////
}
