var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-1",attrs:{"fluid":""}},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.refreshButtonState},on:{"onclick":function($event){return _vm.refresh()}}},[_vm._v("Upload ("+_vm._s(_vm.$store.state.upload.numYetToUpload)+")")])],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":("Show: " + (_vm.hideUploaded ? 'Only unsent data' : 'All data'))},on:{"change":_vm.refreshUi},model:{value:(_vm.hideUploaded),callback:function ($$v) {_vm.hideUploaded=$$v},expression:"hideUploaded"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-expansion-panels',{attrs:{"multiple":""}},[_c('v-expansion-panel',{key:"count-expansion-panel"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.hideUploaded ? ("Count (" + (_vm.rollCalls.length) + ")") : "Count"))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":[
                      { text: 'Ref', value: 'batchNumber' },
                      { text: 'Date', value: 'time' },
                      { text: 'Qty Expected', value: 'qtyExpected' },
                      { text: 'Qty Scanned', value: 'qtyScanned' },
                      { text: 'Kraal', value: 'kraalId' } ],"items":_vm.rollCalls},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.qtyExpected",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.batchQuantity)+" ")]}},{key:"item.qtyScanned",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tags.length)+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('v-expansion-panel',{key:"head-count-expansion-panel"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.hideUploaded ? ("Head Count (" + (_vm.headCounts.length) + ")") : "Head Count"))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":[
                      { text: 'Ref', value: 'batchNumber' },
                      { text: 'Date', value: 'time' },
                      { text: 'Qty Expected', value: 'qtyExpected' },
                      { text: 'Qty Scanned', value: 'qtyScanned' } ],"items":_vm.headCounts},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.qtyExpected",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.batchQuantity)+" ")]}},{key:"item.qtyScanned",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tags.length)+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('v-expansion-panel',{key:"group-weigh-expansion-panel"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.hideUploaded ? ("Group Weigh (" + (_vm.groupWeighs.length) + ")") : "Group Weigh"))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":[
                      { text: 'Ref', value: 'batchNumber' },
                      { text: 'Date', value: 'time' },
                      { text: 'Qty Expected', value: 'qtyExpected' },
                      { text: 'Qty Scanned', value: 'qtyScanned' },
                      { text: 'Mass', value: 'mass' },
                      { text: 'Kraal', value: 'kraalId' } ],"items":_vm.groupWeighs},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.qtyExpected",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.batchQuantity)+" ")]}},{key:"item.qtyScanned",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tags.length)+" ")]}},{key:"item.qty",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.groupWeighQuantity(item))+" ")]}},{key:"item.mass",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.groupWeighMass(item))+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('v-expansion-panel',{key:"dispatch-to-abattoir-gantry-expansion-panel"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.hideUploaded ? ("Abattoir (" + (_vm.dispatchesToAbattoirs.length) + ")") : "Abattoir"))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":[
                      { text: 'Ref', value: 'batchNumber' },
                      { text: 'Date', value: 'time' },
                      { text: 'Qty Expected', value: 'qtyExpected' },
                      { text: 'Qty Scanned', value: 'qtyScanned' },
                      { text: 'Notes', value: 'notes' } ],"items":_vm.dispatchesToAbattoirs},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.qtyExpected",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.batchQuantity)+" ")]}},{key:"item.qtyScanned",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tags.length)+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('v-expansion-panel',{key:"hospital-expansion-panel"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.hideUploaded ? ("Hospital (" + (_vm.hospitalResults.length) + ")") : "Hospital"))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":[
                      { text: 'Date', value: 'time' },
                      { text: 'Animal', value: 'morbidity.sgtin' },
                      { text: 'From', value: 'morbidity.sourceKraalId' },
                      { text: 'To', value: 'morbidity.destinationKraalId' },
                      { text: 'Ailments', value: 'morbidity.ailments' },
                      { text: 'Treatment', value: 'morbidity.appliedTreatment[0].treatment.description' } ],"items":_vm.hospitalResults},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.morbidity.sgtin",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sgtinVisualPart(item.morbidity.sgtin))+" ")]}},{key:"item.morbidity.ailments",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.morbidity.ailments.map(function (a) { return a.ailment; }).join(", "))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row')],1)}
var staticRenderFns = []

export { render, staticRenderFns }