import { default as Vuex, Module } from "vuex";

import AppSettings from "@/app-settings";

class SettingsState {
  appSettings: AppSettings = new AppSettings();
}

class SettingsModule implements Module<SettingsState, any> {
  namespaced = true;
  state: SettingsState = new SettingsState();
  mutations = {
    setAppSettings(state: SettingsState, payload: AppSettings) {
      state.appSettings = payload;
    }
  };
  actions = {
  };
  getters = {
    hslAddress(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return state.appSettings.hardwareServerAddress;
      };
    },
    hardwareServerRestfulPort(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return state.appSettings.hardwareServerRestfulPort;
      };
  }
  };
}
const settingsModule: SettingsModule = new SettingsModule();

export default settingsModule;
