

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import Scan from "@/components/Scan.vue";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { sgtinVisualPart } from "@/helpers/sgtin-ui-friendly-tag";
import { withdrawalDaysFromToday } from "@/helpers/withdrawal";
import { sortTags } from "@/helpers/sort-tags";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton,
    Scan,
  },
})
export default class GroupWeigh extends Vue {
  searchInput: string = "";
  kraalIds: string[] = [];

  kraalId: string = ""; //kraal id auto complete
  batchNumber: string = "";
  notes: string = "";

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  sortTags = sortTags;

  tagUi(tag: Models.Tag): string {
    let ret = this.sgtinVisualPart(tag.sgtin);
    const animal = this.animals.find((a) => a.sgtin === tag.sgtin);
    if (animal) {
      if (animal.owner) ret += ` - ${animal.owner}`;
      if (animal.withdrawalDays !== undefined && animal.withdrawalDays > 0) ret += ` (${animal.withdrawalDays})`;
    }
    return ret;
  }

  sgtinVisualPart(sgtin: string) {
    try {
      return sgtinVisualPart(sgtin);
    } catch (err) {
      console.error(err);
      return "";
    }
  }

  onClearWhenSaved() {
    this.kraalId = "";
    this.batchNumber = "";
    this.$store.commit("groupWeigh/kraalId", "");
    this.$store.commit("groupWeigh/batchNumber", "");
    this.$store.commit("groupWeigh/clearMasses");
    this.$store.commit("scale/clearMass");
  }

  //Gets called when a valid kraal is selected, not when user is typing (that's searchInput)
  @Watch("kraalId")
  async onKraalIdChanged(val: any, oldVal: any) {
    this.$store.commit("groupWeigh/kraalId", val);
  }

  @Watch("batchNumber")
  async onBatchNumberChanged(val: any, oldVal: any) {
    this.$store.commit("groupWeigh/batchNumber", val);
  }

  @Watch("notes")
  async onNotesChanged(val: any, oldVal: any) {
    this.$store.commit("groupWeigh/notes", val);
  }

  searchFilter(s: string) {
    return s.toLowerCase().startsWith(this.searchInput.toLowerCase());
  }

  get searchItems() {
    return this.kraalIds;
  }

  async mounted() {
    this.$store.commit("scan/setAppMode", "group-weigh");
    this.$store.dispatch("power/setPowerMode", "high");
    if (this.$store.state.scan.appState === "saving") {
      this.$store.commit("scan/flagError");
    }

    this.kraalIds = await this.$store.dispatch("data/getKraalIds");
    //await this.$store.dispatch("data/linkSgtinsToAnimalsFromDb", this.tags);

    if (this.tags.length > 0 || this.masses.length > 0) {
      this.kraalId = this.$store.state.groupWeigh.kraalId;
      this.batchNumber = this.$store.state.groupWeigh.batchNumber;
    } else {
      this.$store.commit("groupWeigh/kraalId", "");
      this.$store.commit("groupWeigh/batchNumber", "");
      this.$store.commit("groupWeigh/notes", "");
    }
  }

  iconClick() {
    if (this.selectedCardId === undefined) return;
    let self = this;
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to delete mass?",
      yesAction: function () {
        if (self.selectedCardId === undefined) return;
        //(self.masses.length - self.selectedCardId - 1) because the list is in reverse
        self.$store.commit("groupWeigh/removeMass", self.masses.length - self.selectedCardId - 1);
        self.selectedCardId = undefined;
      },
    });
  }

  containerClick(event: MouseEvent) {
    if (!this.cardClicked) {
      //if card clicked then cardClick() should be called first and cardClicked will be true
      this.selectedCardId = undefined;
    }

    this.cardClicked = false; //reset cardClicked
  }

  cardClicked: boolean = false; //used to detect whether card was clicked or not (if card was not clicked then deselect selected card)

  selectedCardId?: number = -1; //basically the index, so top card is 0, next below is 1, etc... Remember that qty and masses are listed in reverse

  cardClick(event: MouseEvent) {
    if (!event.currentTarget) return;
    let currentTarget = event.currentTarget as any;
    //console.log("card click " + currentTarget.id);

    this.cardClicked = true;
    this.selectedCardId = parseInt(currentTarget.id);
  }

  async onSave() {
    await this.$store.dispatch("groupWeigh/save");
  }

  sgtins: string[] = [];
  animals: (Models.Animal & { withdrawalDays?: number })[] = [];

  get tags() {
    const tags: Models.Tag[] = this.$store.state.scan.tags;
    this.sgtins = tags.map((t) => t.sgtin);
    return this.$store.state.scan.tags;
  }

  @Watch("sgtins")
  onSgtinsChanged(val: string[], oldVal: string[]) {
    const newSgtins = lodash.difference(val, oldVal); //only lookup new sgtins, otherwise it seems that the system looks up too many of the same animals
    const addAnimal = async (sgtin: string) => {
      const animal = await this.$store.dispatch("data/getAnimal", sgtin);
      if (animal) {
        animal.withdrawalDays = withdrawalDaysFromToday(animal);
        this.animals.push(animal);
      }
    };
    for (const sgtin of newSgtins) addAnimal(sgtin);

    //filter out any animals with sgtin no longer being used
    this.animals = this.animals.filter((a) => this.sgtins.find((sgtin) => a.sgtin === sgtin) !== undefined);
  }

  get totalQuantity() {
    if (this.masses.length === 0) return 0;
    return this.masses.map((m: any) => m.quantity).reduce((total: number, num: number) => total + num);
  }

  get masses() {
    return this.$store.state.groupWeigh.massesQuantities;
  }
}
