









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { getAnimalListItems } from "@/helpers/animal-list-persist";
import { AnimalListItem } from "@/models/animal-list-item";

@Component({
  components: {
    DimssaButton,
  },
})
export default class AnimalSearch extends Vue {
  @Prop() nextRouteName!: "animal-data" | "hospital"; //The route to go to after an animal has been successfully searched

  searchInput: string = "";
  isLoadingAnimals: boolean = false;

  @Watch("searchInput")
  onSearchInputChange() {
    this.updateFilteredAnimalListItems();
  }

  @Watch("selectedTagType")
  onSelectedTagTypeChange() {
    this.searchInput = "";
    this.updateFilteredAnimalListItems();
  }

  selectedTagType: "sgtin-ui" | "lf-tag" | "visual-tag" = "sgtin-ui";

  get searchLabel(): string {
    if (this.selectedTagType === "sgtin-ui") return "Giga Tag ID";
    if (this.selectedTagType === "lf-tag") return "LF Tag ID";
    if (this.selectedTagType === "visual-tag") return "Visual Tag ID";
    return "";
  }

  get selectedTagField(): "sgtinUiFriendlyTag" | "allflex" | "visualTag" {
    if (this.selectedTagType === "sgtin-ui") return "sgtinUiFriendlyTag";
    if (this.selectedTagType === "lf-tag") return "allflex";
    /*if (this.selectedTagType === "visual-tag") */ else return "visualTag";
  }

  tagId(ali: AnimalListItem): string {
    let ret: string | undefined = ali.sgtinUiFriendlyTag;
    //if (this.selectedTagType === "sgtin-ui") ret = ali.sgtinUiFriendlyTag;
    if (this.selectedTagType === "lf-tag") ret += ` (${ali.allflex ?? "N/A"})`;
    if (this.selectedTagType === "visual-tag") ret += ` (${ali.visualTag ?? "N/A"})`;
    return ret ?? "";
  }

  async created() {
    this.isLoadingAnimals = true;
    this.animalListItems = await getAnimalListItems();
    this.isLoadingAnimals = false;
    this.updateFilteredAnimalListItems();
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  get searchByTagButtonState(): ButtonState {
    if (!this.selected) {
      return "disabled";
    }
    return "ready";
  }

  selected: any = "";

  @Watch("selected")
  async onSelectedChanged() {
    if (!this.selected.sgtin) return;
    const animal = await this.$store.dispatch("data/getAnimal", this.selected.sgtin);
    this.$router.push({ name: this.nextRouteName, params: { animal: animal } });
  }

  animalListItems: AnimalListItem[] = [];

  filteredAnimalListItems: AnimalListItem[] = [];

  updateFilteredAnimalListItems() {
    let searchInput = this.searchInput;
    //filter out animals with no value for searched tag type
    this.filteredAnimalListItems = this.animalListItems.filter((a) => !!a[this.selectedTagField]);
    if (searchInput) {
      this.filteredAnimalListItems = this.animalListItems.filter((a) => {
        const selectedTagValue = a[this.selectedTagField];
        return selectedTagValue?.toLowerCase().includes(this.searchInput.toLowerCase());
      });
    }
  }

  async mounted() {}

  // async select() {
  //   const animal = await this.$store.dispatch("data/getAnimal", this.selected.sgtin);
  //   this.$router.push({ name: this.nextRouteName, params: { animal: animal } });
  // }
}
