



























import { Component, Prop, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Certificates extends Vue {
  buttonState: ButtonState = "ready";

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  mounted() {
    this.$store.dispatch("certificates/getCertificateCloud");
    this.$store.dispatch("certificates/getCertificateInfoProxy");
  }

  async checkCertificates() {
    this.$store.dispatch("certificates/getCertificateCloud");
  }

  get certificateInfo() {
    return this.$store.state.certificates.certInfo;
  }

  get getCertButtonState() {
    return this.$store.state.certificates.getCertButtonState;
  }

  get setCertButtonState() {
    return this.$store.state.certificates.setCertButtonState;
  }

  async setCertificates() {
    await this.$store.dispatch("certificates/setCertificateProxy");
  }
}
