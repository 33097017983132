class RestApi {
  readonly middleUrl: string = "/HardwareServiceLayer";

  readonly apiRfidSgln: string = this.middleUrl + "/api/Rfid/sgln";

  readonly apiRfidSgtin: string = this.middleUrl + "/api/Rfid/sgtin";

  readonly apiGantryVoltage: string = this.middleUrl + "/api/Gantry/voltage";

  readonly apiGantryStart: string = this.middleUrl + "/api/Gantry/start";

  readonly apiGantryStop: string = this.middleUrl + "/api/Gantry/stop";

  readonly apiHardwareStatus: string = this.middleUrl + "/api/Hardware/Status";

  readonly apiGantryAutoStop: string = this.middleUrl + "/api/Gantry/AutoStop";

  readonly apiGantryAutoStart: string = this.middleUrl + "/api/Gantry/AutoStart";

  readonly apiGantryGetBatchDetails: string = this.middleUrl + "/api/Gantry/getRecords";

  readonly apiGantryIsScanning: string = this.middleUrl + "/api/Gantry/isScanning";
 
  readonly apiRfidSetPower: string = this.middleUrl + "/api/Rfid/Power";

  readonly apiAntennaCount: string = this.middleUrl + "/api/Hardware/Antennas";
  
  // readonly saveGantryResults: string = "/Gantry/SubmitResults";

  // readonly saveRollCall: string = "/Count/SubmitResults";

  // readonly saveDispatchToKraal: string = "/DispatchToKraal/SubmitResults";

  // readonly saveDispatchToAbattoirGantry: string = "/DispatchToAbattoirGantry/SubmitResults";

  // readonly saveGroupWeigh: string = "/GroupWeigh/SubmitResults";

  // readonly feedlots: string = "/shared/Feedlots";
  // readonly colours: string = "/shared/Colors";
}

export const restApi: RestApi = new RestApi();
