




































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import Scan from "@/components/Scan.vue";
import { sgtinVisualPart } from "@/helpers/sgtin-ui-friendly-tag";
import { withdrawalDaysFromToday } from "@/helpers/withdrawal";
import { sortTags } from "@/helpers/sort-tags";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";

@Component({
  components: {
    Scan,
    DimssaButton,
  },
})
export default class Count extends Vue {
  @Prop() saveEnabled!: boolean; //This prop gets set in router and is pretty much just forwarded to the Scan component

  searchInput: string = "";
  kraalIds: string[] = [];

  kraalId: string = ""; //kraalId autocomplete text field model
  batchNumber: string = "";
  batchQuantity: string = "";
  notes: string = "";

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  tagPageIndex = 0;
  tagPageCount = 100;

  sortTags = sortTags;

  tagUi(tag: Models.Tag): string {
    let ret = this.sgtinVisualPart(tag.sgtin);
    const animal = this.animals.find((a) => a.sgtin === tag.sgtin);
    if (animal) {
      if (animal.owner) ret += ` - ${animal.owner}`;
      if (animal.withdrawalDays !== undefined && animal.withdrawalDays > 0) ret += ` (${animal.withdrawalDays})`;
      if (animal.producer) ret += ` - ${animal.producer}`;
      if (animal.allflex?.startsWith("LA.516")) ret += ` - NAM`;
      if (animal.allflex?.startsWith("LA.072")) ret += ` - BWA`;
    }
    return ret;
  }

  sgtinVisualPart(sgtin: string) {
    try {
      return sgtinVisualPart(sgtin);
    } catch (err) {
      console.error(err);
      return "";
    }
  }

  onClearWhenSaved() {
    this.batchNumber = "";
    this.batchQuantity = "";
    this.notes = "";
    this.$store.commit("count/batchQuantity", "");
    this.$store.commit("count/batchNumber", "");
    this.kraalId = "";
    this.$store.commit("count/kraalId", "");
  }

  searchFilter(s: string) {
    return s.toLowerCase().startsWith(this.searchInput.toLowerCase());
  }

  //used for autocomplete
  get searchItems() {
    return this.kraalIds;
  }

  sgtins: string[] = [];
  animals: (Models.Animal & { withdrawalDays?: number })[] = [];

  get tags() {
    const tags: Models.Tag[] = this.$store.state.scan.tags;
    this.sgtins = tags.map((t) => t.sgtin);
    return this.$store.state.scan.tags;
  }

  animalQueue: { sgtin: string }[] = [];
  isProcessingQueue: boolean = false;

  async processQueue() {
    let item;
    this.isProcessingQueue = true;
    while ((item = this.animalQueue.shift())) {
      const animal = await this.$store.dispatch("data/getAnimal", item.sgtin);
      if (animal) {
        animal.withdrawalDays = withdrawalDaysFromToday(animal);
        this.animals.push(animal);
      }
      //clear list if tags cleared while busy
      if (this.sgtins.length === 0) {
        this.animalQueue = [];
        this.animals = [];
        break;
      }
      //await new Promise((resolve, _) => setTimeout(resolve, 50));
    }
    this.isProcessingQueue = false;
  }

  @Watch("sgtins")
  onSgtinsChanged(val: string[], oldVal: string[]) {
    const newSgtins = lodash.difference(val, oldVal); //only lookup new sgtins, otherwise it seems that the system looks up too many of the same animals
    const addAnimal = async (sgtin: string) => {
      this.animalQueue.push({ sgtin });
      if (!this.isProcessingQueue) {
        this.processQueue();
      }
    };
    for (const sgtin of newSgtins) addAnimal(sgtin);

    //Clear animals if tags are cleared
    if (val.length === 0) {
      this.animals = [];
    }
  }

  //Gets called when a valid kraal is selected, not when user is typing (that's searchInput)
  @Watch("kraalId")
  async onKraalIdChanged(val: any, oldVal: any) {
    this.$store.commit("count/kraalId", val);
  }

  @Watch("batchNumber")
  async onBatchNumberChanged(val: any, oldVal: any) {
    this.$store.commit("count/batchNumber", val);
  }

  @Watch("batchQuantity")
  async onBatchQuantityChanged(val: any, oldVal: any) {
    this.$store.commit("count/batchQuantity", parseInt(val));
  }

  @Watch("notes")
  async onNotesChanged(val: any, oldVal: any) {
    this.$store.commit("count/notes", val);
  }

  async mounted() {
    //saveEnabled is a prop which gets set by vue-router depending on whether we pressed "Count" or "Count Without Save"
    if (this.saveEnabled) this.$store.commit("scan/setAppMode", "count");
    else this.$store.commit("scan/setAppMode", "count-without-save");

    //If we mount and appState was saving then flag error since saving wasn't finished.
    if (this.$store.state.scan.appState === "saving") {
      this.$store.commit("scan/flagError");
    }

    //get kraalIds for autocomplete
    this.kraalIds = await this.$store.dispatch("data/getKraalIds");
    //await this.$store.dispatch("data/linkSgtinsToAnimalsFromDb", this.tags);

    if (this.tags.length > 0) {
      this.batchQuantity = this.$store.state.count.batchQuantity ?? "";
      this.batchNumber = this.$store.state.count.batchNumber;
      //Keep kraal id if there are still tags
      this.kraalId = this.$store.state.count.kraalId;
    } else {
      this.$store.commit("count/batchQuantity", "");
      this.$store.commit("count/batchNumber", "");
      //If no tags then clear kraal id
      this.$store.commit("count/kraalId", "");
      this.$store.commit("count/notes", "");
    }
    this.$store.dispatch("power/setPowerMode", "high");
  }

  async onSave() {
    await this.$store.dispatch("count/save");
  }
}
