














































import { Component, Prop, Vue } from "vue-property-decorator";
import * as Models from "@gigalot/data-models";
import moment from "moment";

@Component
export default class AnimalEventHistory extends Vue {
  @Prop() events!: Models.AnimalEvent[];
  @Prop() showAdg!: boolean;

  get moment() {
    return this.$store.state.moment;
  }

  eventCardBackgroundColour(event: Models.AnimalEvent) {
    if (event.type === "scanning_roll-call") return "#1867C1";
    if (event.type === "scanning_group-weigh") return "#FD9333";
    if (event.type === "scanning_dispatch-to-kraal") return "";
    if (event.type === "scanning_dispatch-to-abattoir-gantry") return "";

    if (event.type === "scanning_head-count") return "#120BEF";

    if (event.type === "processing_new") return "#7ED957";
    if (event.type === "processing_reprocess") return "#F0F4C3";
    if (event.type === "processing_reweigh") return "#05E1F6";
    if (event.type === "processing_reimplant") return "#F36EFF";
    if (event.type === "processing_dispatch-to-abattoir-processed") return "";

    if (event.type === "hospital") return "#F41919";

    return "";
  }

  eventType(event: Models.AnimalEvent) {
    if (event.type === "scanning_roll-call") return "Count";
    if (event.type === "scanning_group-weigh") return "Group Weigh";
    if (event.type === "scanning_dispatch-to-kraal") return "Count";
    if (event.type === "scanning_dispatch-to-abattoir-gantry") return "Dispatch to Abattoir Gantry";

    if (event.type === "scanning_head-count") return "Head Count";

    if (event.type === "processing_new") return "New Intake";
    if (event.type === "processing_reprocess") return "Reprocess";
    if (event.type === "processing_reweigh") return "Reweigh";
    if (event.type === "processing_reimplant") return "Reimplant";
    if (event.type === "processing_dispatch-to-abattoir-processed") return "Dispatch to Abattoir";

    if (event.type === "hospital") return "Hospital";

    return event.type;
  }

  // gainBetweenEvents(event: Models.AnimalEvent, index: number) {
  //   if (index >= this.animal.events.length) return "N/A";
  //   let previousEvent = this.animal.events[index + 1];
  //   if (event.mass === undefined || previousEvent.mass === undefined) return "N/A";
  //   return event.mass - previousEvent.mass;
  // }

  daysBetweenEvents(event: Models.AnimalEvent, index: number) {
    if (index >= this.events.length) return "N/A";
    let previousEvent = this.events[index + 1];
    return moment(event.time).diff(moment(previousEvent.time), "days");
  }
}
