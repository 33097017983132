import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import Uploadable, { UploadableScanningResult } from "@/models/uploadable";
import { geolocation } from "@/helpers/geolocation";
import axios from "axios";
import AppSettings from "@/app-settings";
import { restApi } from "@/rest-api";

class AutoCountState {
  batchNumber?: string;
  batchQuantity?: number;
  kraalId?: string;
  kraal2Id?: string;
  ignoreGuids: string[] = [];
  webSocket?: WebSocket;
  deviceConnected?: boolean;
  statusMessage?: string;
  antennaCount: number = 1;
}

export default new (class AutoCount implements Module<AutoCountState, any> {
  namespaced = true;
  state: AutoCountState = new AutoCountState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    addIgnoreGuid(state: AutoCountState, guidtoIgnore: string) {
      state.ignoreGuids.push(guidtoIgnore);
    },
    batchNumber(state: AutoCountState, batchNumber: string | undefined) {
      state.batchNumber = batchNumber;
    },
    batchQuantity(state: AutoCountState, batchQuantity: number | undefined) {
      state.batchQuantity = batchQuantity;
    },
    // notes(state: AutoCountState, notes?: string) {
    //   state.notes = notes;
    // },
    kraalId(state: AutoCountState, kraalId: string | undefined) {
      state.kraalId = kraalId;
    },
    kraal2Id(state: AutoCountState, kraal2Id: string | undefined) {
      state.kraal2Id = kraal2Id;
    },
    setStatusMessage(state: AutoCountState, payload: string) {
      //TODO: change to error message
      state.statusMessage = payload;
    },
    antennaCount(state: AutoCountState, payload: number) {
      state.antennaCount = payload;
    }

  };
  actions = {
    async connect(context: ActionContext<AutoCountState, any>, o: { jumpToCountingState: boolean; }) {
      let wsProtocol = AppSettings.getHardwareWebSocketProtocol(context.rootState.settings.appSettings);
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let readerPort = context.rootState.settings.appSettings.hardwareServerReaderPort;

      let webSocket: WebSocket = new WebSocket(`${wsProtocol}://${address}:${readerPort}`);
      webSocket.onopen = async event => {
        context.state.deviceConnected = true;

        if (context.rootState.isOrca) {
          context.commit("setStatusMessage", "Web Socket opened.");
          context.commit("setAppState", "counting");
        } else {
          context.commit("setStatusMessage", "Web Socket opened. Starting Reader.");
        }
      };
      webSocket.onerror = event => {
        context.commit("setStatusMessage", "Failed to connect: Web socket error.");
        context.commit("flagError");
        console.log("webSocket onerror");
        context.state.deviceConnected = false;

      };
      webSocket.onmessage = message => {
        let sgtins: string[] = message.data
          .split(" ")
          .join("") //remove all spaces that might be in the line with the sgtin
          .split("\n");

        sgtins = sgtins.filter((sgtin: string) => sgtin.length > 0);
      };
      webSocket.onclose = event => {
        context.state.deviceConnected = false;
        console.log("webSocket onclose");
      };
      context.commit("setWebSocket", webSocket);
    },
    disconnect(context: ActionContext<AutoCountState, any>) {
      if (context.state.webSocket) context.state.webSocket.close();
    },
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async save(context: ActionContext<AutoCountState, any>, rollCalls: any[]) {
      for (let rollCall of rollCalls) {
        await context.dispatch("data/addRollCall", rollCall, { root: true });
        context.commit("upload/numYetToUpload", "increment", { root: true });
      }
      try {
        context.dispatch("upload/upload", undefined, { root: true });
      } catch (e) {
        //silent failure is fine here, upload will likely fail here anyway if the user is still on the scanner's wifi
      }
    },
    async getAntennaCount(context: ActionContext<AutoCountState, any>) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      try {

        let url = `${protocol}://${address}:${restfulPort}${restApi.apiAntennaCount}`;
        console.log(url);

        let response = await axios.get(url, { timeout: 10000 });
        if (response.data === false) {
          context.commit("setStatusMessage", "Reader connected but reader failed to start.");
          return;
        }
        context.commit("antennaCount", response.data);
      } catch (error) {
        context.commit("antennaCount", 1);
      }
    },
    async getGeolocation() {
      //try get geolocation
      try {
        const p = await geolocation({ enableHighAccuracy: false, maximumAge: 0, timeout: 15 * 1000 });
        let lat = p.coords.latitude;
        let lon = p.coords.longitude;
        return { lat, lon };
      } catch (err) {
        console.error("Could not get geolocation: ", err);

      }
      return { lat: 0, lon: 0 };
    },
    async startAutoCount(context: ActionContext<AutoCountState, any>, o: { batch: string, autoUpload: boolean, readInterval: number, kraal: string, kraal2: string, batchGuid: string, lat: string, lon: string }) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      try {

        let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryAutoStart}?batch=${o.batch}&readInterval=${o.readInterval}&kraal=${o.kraal}&kraal2=${o.kraal2}&batchGuid=${o.batchGuid}&lat=${o.lat}&lon=${o.lon}&autoUpload=${o.autoUpload}`;
        console.log(url);

        let response = await axios.get(url, { timeout: 10000 });
        if (response.data === false) {
          context.commit("setStatusMessage", "Reader connected but reader failed to start.");
          return;
        }

        context.commit(
          "setStatusMessage",
          "Scanning..."
        );
      } catch (error) {
        context.commit("setStatusMessage", "Failed to connect: Error starting reader.");
        context.commit("flagError");
      }
    },
    async stopAutoCount(context: ActionContext<AutoCountState, any>) {
      context.commit("setStatusMessage", "Stopping auto scan...");

      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryAutoStop}`;
      try {
        let response = await axios.get(url, {
          timeout: 10000
        });
        context.commit("setStatusMessage", "Scanning stopped.");
      } catch (error) {
        context.commit("setStatusMessage", "Failed to stop auto scanner.");
        context.commit("flagError");
      }

    },
    async getBatchDetails(context: ActionContext<AutoCountState, any>) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryGetBatchDetails}`;
      try {

        let response = await axios.get(url, {
          timeout: 10000
        });
        return response;

      } catch (error) {
        // context.commit("setStatusMessage", "Failed to stop auto scanner.");
        // context.commit("flagError");
      }

    },
    async isDeviceScanning(context: ActionContext<AutoCountState, any>) {
      let address = context.rootState.settings.appSettings.hardwareServerAddress;
      let restfulPort = context.rootState.settings.appSettings.hardwareServerRestfulPort;
      let protocol = AppSettings.getHardwareRestfulProtocol(context.rootState.settings.appSettings);
      let url = `${protocol}://${address}:${restfulPort}${restApi.apiGantryIsScanning}`;
      try {

        let response = await axios.get(url, {
          timeout: 10000
        });
        return response;
        // context.commit("setStatusMessage", "Scanning stopped.");
      } catch (error) {
        // context.commit("setStatusMessage", "Failed to stop auto scanner.");
        // context.commit("flagError");
      }
    }

  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    getIgnoreGuids(state: AutoCountState) {
      return state.ignoreGuids;
    },
    getConnectionState(state: AutoCountState) {
      return state.deviceConnected || false;
    }
  };
})();
