import * as Models from "@gigalot/data-models";
import { ScanningResult } from "@gigalot/data-models";
import { v4 } from "uuid";

export function convertAutoscanToBatch(autoData: any, upstreamMetadata: any): Models.ScanningResult[] {
    autoData = JSON.parse(autoData.data);
    let scanRecords = [];
    for (let i = 0; i < autoData.length; i++) {
        //Each batch
        let scannedTags: Models.Tag[] = [];
        for (let record of autoData[i].Records) {
            const scanResult = { typename: "Tag", guid: v4(), ScannedTime: record.Values._time, sgtin: record.Values._value, scannedAntennae: parseInt(record.Values.antenna) };
            scannedTags.push(scanResult);
        }
        let batchNo = autoData[i].Records[0].Values.batch;
        let kraal = autoData[i].Records[0].Values.kraal;
        let batchGuid = autoData[i].Records[0].Values.batchGuid;
        //TODO: add to batch
        let scanRecord: any = { typename: "ScanningResult", autoBatchNumber: batchNo, batchNumber: batchNo, batchQuantity: scannedTags.length.toString(), guid: batchGuid, kraalId: kraal, mass: [], metadata: upstreamMetadata, tags: scannedTags, time: Date.now(), scanningFunction: "roll-call" };
        if (scanRecord) scanRecords.push(scanRecord);
    }

    return scanRecords;
}

export function getGPSLocation(autoData: any, batchGuid: string) {
    autoData = JSON.parse(autoData.data);
    let scanRecords = [];
    let gpsCoordinates = { lat: 0, lon: 0 };
    for (let i = 0; i < autoData.length; i++) {
        //Each batch
        //where batch matches batchGuid
        //get 
        let lat = autoData[i].Records[0].Values.lat;
        let lon = autoData[i].Records[0].Values.lon;
        gpsCoordinates.lat = lat;
        gpsCoordinates.lon = lon;
    }
    return gpsCoordinates;
}