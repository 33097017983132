export function formatDateToSqlDateTime() {
  //Returns date and time in the format YYYY-MM-DD hh:mm:ss
  return new Date().toISOString().slice(0, 19).replace("T", " ");
}

//Takes a date object and returns a string in the form YYYYMMDD
export function getYyyyMmDd(date: Date): string {
  //let date = new Date();
  const y = "" + date.getFullYear();
  let t = date.getMonth() + 1;
  const m = t <= 9 ? "0" + t : "" + t;
  t = date.getDate();
  const d = t <= 9 ? "0" + t : "" + t;
  return y + m + d;
}

//Takes a date object and returns a string representing the time of day to the second in the form hhmmss
export function getHhMmSs(date: Date): string {
  let t = date.getHours();
  const h = t <= 9 ? "0" + t : "" + t;
  t = date.getMinutes();
  const m = t <= 9 ? "0" + t : "" + t;
  t = date.getSeconds();
  const s = t <= 9 ? "0" + t : "" + t;
  return h + m + s;
}

// export function getYyyyMmDdHhMmSs(date: Date): string {
//   return `${getYyyyMmDd(date)}-${getHhMmSs(date)}`;
// }
