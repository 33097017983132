



















import { Component, Prop, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { AppState } from "@/store/modules/scan";
import lodash from "lodash";


@Component({
  components: {
    DimssaButton,
  },
})
export default class Scan extends Vue {
  @Prop() onSaveProp!: Function; //this function should throw exceptions for errors to bring up a popup to notify user
  @Prop() onClearWhenSavedProp!: Function; //Scan.vue will clear the scan module's tags, this function should handle anything specific to the view using the Scan component
  //@Prop() scanButtonText?: string;
  @Prop() saveEnabled?: boolean;
  @Prop() skipTagChecking?: boolean;

  get scanButtonText() {
    //return "Scan";
    let appState: AppState = this.$store.state.scan.appState;
    let countButtonBackoff: AppState = this.$store.state.scan.countButtonBackoff;
    let tags: AppState = this.$store.state.scan.tags;

    if (this.skipTagChecking) return "Scan"; //no checking just scanning

    if (appState === "connected" && !countButtonBackoff && tags.length === 0) return "Scan"; //ready to start scanning from checking

    switch (appState) {
      case "menu":
      case "connecting":
      case "connected":
      case "stopping_before_counting":
      case "stopped_before_counting":
        return "Check";
      case "counting":
      case "stopping_counting":
      case "stopped_counting":
      case "resuming_counting":
      case "saving":
      case "saved":
        return "Scan";
    }
    return "scanButtonText() error";
  }

  mounted() {
    if (this.$store.state.isOrca) this.$store.dispatch("scan/connect", { jumpToCountingState: true });
  }

  destroyed() {
    if (this.$store.state.scan.appState === "saved") {
      this.onClear();
    }
    if (this.$store.state.isOrca) this.$store.commit("scan/disconnect");
  }

  onCount() {
    
    //console.log("onCount");
    let appState: AppState = this.$store.state.scan.appState;
    let error: boolean = this.$store.state.scan.error;
    if (appState === "menu" || (appState === "connecting" && error)) {
      this.$store.dispatch("scan/connect");
    } else if (appState == "resuming_counting" && error) {
      this.$store.dispatch("scan/connect", { jumpToCountingState: true });
    } else if (appState !== "counting") {
      this.$store.dispatch("scan/startCount");
    }
  }

  onStop() {
    this.$store.dispatch("scan/stopCount");
  }

  countButtonBackoffDebounced: any = null;

  onClear() {
    let appState: AppState = this.$store.state.scan.appState;
    if (appState === "connected" || appState === "stopped_before_counting" || appState === "stopping_before_counting" || appState === "connecting") {
      if (appState !== "stopped_before_counting") {
        if (this.countButtonBackoffDebounced) {
          this.countButtonBackoffDebounced.cancel();
        }
        //wait 5 seconds before enabling count button, if stopped then Check button will show in place of Count, that can enable immediately
        this.$store.commit("scan/setCountButtonBackoff", true);
        this.countButtonBackoffDebounced = lodash.debounce(() => {
          this.$store.commit("scan/setCountButtonBackoff", false);
        }, this.$store.state.scan.countButtonBackoffTime);
        this.countButtonBackoffDebounced();
      }
      this.$store.commit("scan/clearTags");
    } else if (appState === "counting" || appState === "stopped_counting" || appState === "stopping_counting" || appState === "resuming_counting") {
      this.$store.commit("popup/displayYesNo", {
        message: "Are you sure you want to clear?",
        yesAction: () => {
          this.$store.commit("scan/clearTags");
          this.$store.commit("popup/hide");
        },
      });
    } else if (this.$store.state.scan.appState === "saved") {
      this.$store.commit("scan/clearTags");
      this.$store.commit("scan/setAppState", /*this.isOrca ? "counting" :*/ "menu");
      this.onClearWhenSavedProp();
    } else if (appState === "saving" && this.$store.state.scan.error) {
      this.$store.commit("popup/displayYesNo", {
        message: "Are you sure you want to clear?",
        yesAction: () => {
          this.$store.commit("scan/clearTags");
          this.$store.commit("scan/setAppState", "menu");
          this.$store.commit("popup/hide");
        },
      });
    }
  }

  async save() {
    if (this.$store.state.scan.appState === "counting") {
      this.$store.dispatch("scan/sendStopCommand");
    }
    try {
      this.$store.commit("scan/setAppState", "saving");
      await this.onSaveProp(); //callback should throw exception with error message that will be displayed in a popup
      this.$store.commit("scan/setAppState", "saved");
    } catch (e) {
      this.$store.commit("scan/flagError");
      this.$store.commit("popup/displayOk", "Failed to save. " + e);
    }
  }

  onSave() {
    if (this.$store.state.scan.appState === "saved") {
      return;
    }
    if (this.$store.state.scan.appState === "saving" && this.$store.state.scan.error) {
      this.save();
    } else {
      this.$store.commit("popup/displayYesNo", {
        message: "Are you sure you want to save?",
        yesAction: () => {
          this.save();
        },
      });
    }
    //console.log("process.env.NODE_ENV: " + process.env.NODE_ENV);
  }

  get scanStatusMessage(): string {
    return this.$store.getters["scan/getScanStatusMessage"];
    // let error: boolean = this.$store.state.scan.error;
    // switch (this.$store.state.scan.appState) {
    //   case "menu": {
    //     if (this.$store.state.scan.appMode === "count" || this.$store.state.scan.appMode === "move")
    //       return "Ready. Press Scan to begin";

    //     return "Ready. Press Check to begin";
    //   }
    //   case "connecting": {
    //     return error ? "Error connecting" : "Connecting...";
    //   }
    //   case "connected": {
    //     return "Connected and checking for tags...";
    //   }
    //   case "stopping_before_counting": {
    //     return error ? "Error stopping scan" : "Stopping scan...";
    //   }
    //   case "stopped_before_counting": {
    //     return "Scanning stopped before counting was started";
    //   }
    //   case "counting": {
    //     return "Counting tags";
    //   }
    //   case "stopping_counting": {
    //     return error ? "Error stopping counting" : "Stopping counting";
    //   }
    //   case "stopped_counting": {
    //     return "Counting stopped";
    //   }
    //   case "resuming_counting": {
    //     return error ? "Error resuming counting" : "Resuming counting";
    //   }
    //   case "saving": {
    //     return error ? "Error saving" : "Saving";
    //   }
    //   case "saved": {
    //     return "Saved";
    //   }
    //   default: {
    //     return "";
    //   }
    // }
  }

  get countButtonState(): ButtonState {
    let appState: AppState = this.$store.state.scan.appState;
    let error: boolean = this.$store.state.scan.error;
    if (appState === "menu") {
      return "ready";
    } else if (appState === "counting") {
      return "success";
      //return "disabled";
    } else if (this.$store.state.scan.countButtonBackoff || (appState === "connected" && this.$store.state.scan.tags.length > 0)) {
      return "disabled";
    } else if (!error && (appState === "connecting" || appState === "resuming_counting")) {
      return "busy";
    } else if (error && (appState === "connecting" || appState === "resuming_counting")) {
      return "error";
    } else if (appState === "saving" || appState === "saved") {
      return "disabled";
    } else {
      return "ready";
    }
    //return this.countButtonState_;
  }

  get stopButtonState(): ButtonState {
    let appState: AppState = this.$store.state.scan.appState;
    let error: boolean = this.$store.state.scan.error;
    if (appState === "menu") {
      return "disabled";
    } else if (appState === "stopping_before_counting" || appState === "stopping_counting") {
      if (error) {
        return "error";
      } else {
        return "busy";
      }
    } else if (appState === "connecting" || appState === "resuming_counting" || appState === "stopped_before_counting" || appState === "stopped_counting") {
      //return "success";
      return "disabled";
    } else if (appState === "saving" || appState === "saved") {
      return "disabled";
    }
    return "ready";
  }

  get saveButtonState(): ButtonState {
    let appState: AppState = this.$store.state.scan.appState;
    let isError: boolean = this.$store.state.scan.error;
    if (this.$store.state.scan.tags.length == 0) {
      return "disabled";
    } else if (appState === "saving" && !isError) {
      return "busy";
    } else if (appState === "saving" && isError) {
      return "error";
    } else if (appState === "saved") {
      return "success";
    } else if (appState === "counting" || appState === "stopping_counting" || appState === "stopped_counting" || appState === "resuming_counting") {
      return "ready";
    } else {
      return "disabled";
    }
    //return this.saveButtonState_;
  }

  get clearButtonState(): ButtonState {
    if (this.$store.state.scan.tags.length > 0) {
      return "ready";
    } else {
      return "disabled";
    }
  }
}
