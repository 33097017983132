































import { Component, Vue } from "vue-property-decorator";
import { ButtonState, DimssaButton } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
    Keypress: () => import("vue-keypress"),
  },
})
export default class Login extends Vue {
  show: boolean = false;
  email: string = "";
  password: string = "";

  errorMessage: string = "";

  buttonState: ButtonState = "ready";

  rules: { [ruleName: string]: (value: string) => boolean | string } = {
    required: (value: string) => !!value || "Required.",
    min: (v: string) => v.length >= 6 || "Min 6 characters",
    //emailMatch: () => "The email and password you entered don't match"
  };

 passwordUrl(){
    if (this.$store.state.environment === "production")
    {
      return "https://password.gigalot.co.za";
    }
    else return "https://password.gigalot.systems";
  } 


  async signInWithEmailPassword() {
    this.buttonState = "busy";
    //TODO: use rules used in text fields to test email and password. Use error message in popup
    try {
      await this.$store.dispatch("user/signInWithEmailPassword", {
        email: this.email.trim(),
        password: this.password,
      });
      this.buttonState = "success";
    } catch (err) {
      this.buttonState = "error";
      console.log("signInWithEmailPassword error: " + err);
      this.errorMessage = "Error signing in: " + err;
    }
  }

  mounted() {
    // console.log("Login mounted");
    // console.log(firebase.auth().currentUser);
  }
}
