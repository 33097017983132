/*
List of animals that should only be stored in RAM not in vuex's persist
*/

import { AnimalListItem } from "@/models/animal-list-item";
import store from "@/store/store";

let _animalListItems: AnimalListItem[] = [];

export function setAnimalListItems(animalListItems: AnimalListItem[]) {
  _animalListItems = animalListItems;
}

export async function getAnimalListItems() {
  if (_animalListItems.length === 0) {
    _animalListItems = await store.dispatch("data/getAnimalList");
  }
  return _animalListItems;
}
