










import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
//import AppSettings from "@/app-settings";

@Component({
  components: {
    DimssaButton,
  },
})
export default class System extends Vue {
  usageBytes?: number = 0;
  quotaBytes?: number = 0; //free space available

  get usageUiFriendly() {
    if (!this.usageBytes) return "";
    return (this.usageBytes / 1000 / 1000).toFixed(2);
  }

  get quotaUiFriendly() {
    if (!this.quotaBytes) return "";
    return (this.quotaBytes / 1000 / 1000).toFixed(2);
  }

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  mounted() {
    let self = this;
    if ("storage" in navigator && "estimate" in navigator.storage) {
      navigator.storage.estimate().then(({ usage, quota }) => {
        //console.log(`Using ${usage} out of ${quota} bytes.`);
        self.usageBytes = usage;
        self.quotaBytes = quota;
      });
    }
  }
}
