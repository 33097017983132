



























































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as DataModels from "@gigalot/data-models";
import { UploadableScanningResult, UploadableModel, HospitalResult } from "@/models/uploadable";
import { ScanningResult } from "@gigalot/data-models";
import { sgtinVisualPart } from "@/helpers/sgtin-ui-friendly-tag";
//TODO: make this a table view with columns, will be easier to understand with column headers

@Component({
  components: {
    DimssaButton,
  },
})
export default class History extends Vue {
  hideUploaded: boolean = true;

  tab: any = null;

  refreshButtonState: ButtonState = "ready";

  sgtinVisualPart = sgtinVisualPart;

  rollCalls: UploadableScanningResult[] = [];
  headCounts: UploadableScanningResult[] = [];
  //dispatches: UploadableScanningResult[] = [];
  //dispatchesToKraals: UploadableScanningResult[] = [];
  dispatchesToAbattoirs: UploadableScanningResult[] = [];
  groupWeighs: UploadableScanningResult[] = [];
  hospitalResults: HospitalResult[] = [];
  groupWeighQuantity(scanningResult: ScanningResult) {
    let totalQty = scanningResult.mass.map((me) => me.quantity as number).reduce((p, c) => p + c);
    if (totalQty < scanningResult.tags.length) totalQty = scanningResult.tags.length;
    return totalQty;
  }

  groupWeighMass(scanningResult: ScanningResult) {
    let qty = this.groupWeighQuantity(scanningResult);
    let totMass = scanningResult.mass.map((me) => me.mass).reduce((p, c) => p + c);
    let avgMass = qty > 0 ? Math.round(totMass / qty) : "";
    return `${totMass}kg (${avgMass}kg)`;
  }

  get moment() {
    return this.$store.state.moment;
  }

  // get refreshButtonState(): ButtonState {
  //   if (this.$store.state.upload.isBusyUploading) {
  //     return "busy";
  //   } else {
  //     return "ready";
  //   }
  // }

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
  }

  async mounted() {
    this.refresh();
  }

  sortOnTime(ascending: boolean) {
    return (a: any, b: any) => {
      let ret: number = 0;
      if (a.time < b.time) ret = -1;
      if (a.time === b.time) ret = 0;
      if (a.time > b.time) ret = 1;

      if (!ascending) ret *= -1;

      return ret;
    };
  }

  async refreshUi() {
    this.rollCalls = await this.$store.dispatch("data/getRollCalls");
    if (this.hideUploaded) {
      //hide already uploaded results, show only results that still need to be uploaded
      this.rollCalls = this.rollCalls.filter((rollCall) => !rollCall.uploaded);
    }
    this.headCounts = await this.$store.dispatch("data/getHeadCounts");
    if (this.hideUploaded) {
      //hide already uploaded results, show only results that still need to be uploaded
      this.headCounts = this.headCounts.filter((headCount) => !headCount.uploaded);
    }
    // this.dispatchesToKraals = await this.$store.dispatch("data/getDispatchesToKraals");
    // if (this.hideUploaded) {
    //   //hide already uploaded results, show only results that still need to be uploaded
    //   this.dispatchesToKraals = this.dispatchesToKraals.filter(dispatch => !dispatch.uploaded);
    // }
    this.dispatchesToAbattoirs = await this.$store.dispatch("data/getDispatchesToAbattoirs");
    if (this.hideUploaded) {
      //hide already uploaded results, show only results that still need to be uploaded
      this.dispatchesToAbattoirs = this.dispatchesToAbattoirs.filter((dispatch) => !dispatch.uploaded);
    }
    this.groupWeighs = await this.$store.dispatch("data/getGroupWeighs");
    if (this.hideUploaded) {
      //hide already uploaded results, show only results that still need to be uploaded
      this.groupWeighs = this.groupWeighs.filter((dispatch) => !dispatch.uploaded);
    }

    this.hospitalResults = await this.$store.dispatch("data/getHospitalResults");
    if (this.hideUploaded) {
      //hide already uploaded results, show only results that still need to be uploaded
      this.hospitalResults = this.hospitalResults.filter((dispatch) => !dispatch.uploaded);
    }

    this.hospitalResults.sort(this.sortOnTime(false));
    this.rollCalls.sort(this.sortOnTime(false));
    this.headCounts.sort(this.sortOnTime(false));
    //this.dispatchesToKraals.sort(this.sortOnTime(false));
    this.dispatchesToAbattoirs.sort(this.sortOnTime(false));
    this.groupWeighs.sort(this.sortOnTime(false));
  }

  async refresh() {
    //try {
    if (this.$store.state.upload.isBusyUploading) {
      this.refreshButtonState = "busy";
      this.refreshUi();
      return;
    }
    this.refreshButtonState = "busy";
    await this.refreshUi();
    await this.$store.dispatch("upload/upload");
    await this.refreshUi();
    this.refreshButtonState = this.$store.state.upload.uploadError ? "error" : "success";
    // } catch (e) {
    // } finally {
    // }
  }

  @Watch("$store.state.upload.isBusyUploading")
  onIsBusyUploadingChanged(val: any) {
    console.log("onIsBusyUploadingChanged", val);
  }
}
