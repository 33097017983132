import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import Uploadable, { UploadableScanningResult } from "@/models/uploadable";
import { getHhMmSs, getYyyyMmDd } from "@/helpers/date-time";
import { geolocation } from "@/helpers/geolocation";

class DispatchToAbattoirState {
  batchNumber?: string;
  batchQuantity?: number;
  notes?: string;
}

export default new (class DispatchToAbattoir implements Module<DispatchToAbattoirState, any> {
  namespaced = true;
  state: DispatchToAbattoirState = new DispatchToAbattoirState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    batchNumber(state: DispatchToAbattoirState, batchNumber: string | undefined) {
      state.batchNumber = batchNumber;
    },
    batchQuantity(state: DispatchToAbattoirState, batchQuantity: number | undefined) {
      state.batchQuantity = batchQuantity;
    },
    notes(state: DispatchToAbattoirState, notes?: string) {
      state.notes = notes;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async save(context: ActionContext<DispatchToAbattoirState, any>) {
      if (!context.state.batchNumber) {
        throw Error("No batch reference entered");
      }
      if (context.state.batchQuantity === undefined) {
        throw Error("No quantity entered");
      } else if (context.state.batchQuantity <= 0 || isNaN(context.state.batchQuantity)) {
        throw Error("Invalid batch quantity");
      }
      let metadata: DataModels.UpstreamMetadata = context.rootGetters["upload/getUpstreamMetadata"]();
      let dispatchToAbattoir: UploadableScanningResult = Object.assign(
        new DataModels.ScanningResult("dispatch-to-abattoir-gantry", Date.now(), metadata),
        new Uploadable()
      );
      dispatchToAbattoir.tags = context.rootState.scan.tags;

      const numTags = dispatchToAbattoir.tags.length;
      if (context.state.batchQuantity < numTags) {
        throw Error("Quantity can not be lower than number of tags scanned.");
      }
      dispatchToAbattoir.batchQuantity = "" + context.state.batchQuantity;

      ////////
      // Batch quantity, if not set then use num tags. If set but lower than num tags then use num tags instead.
      // let batchQuantity: number;
      // const numTags = dispatchToAbattoir.tags.length;
      // if (context.state.batchQuantity !== undefined) {
      //   const bq = context.state.batchQuantity;
      //   batchQuantity = numTags > bq ? numTags : bq;
      // } else {
      //   batchQuantity = numTags
      // }
      // dispatchToAbattoir.batchQuantity = "" + batchQuantity;
      ////////

      if (!context.state.batchNumber) {
        context.commit("batchNumber", `${getYyyyMmDd(new Date(dispatchToAbattoir.time)).slice(2)}${getHhMmSs(new Date(dispatchToAbattoir.time))}`);
      }

      //try get geolocation
      try {
        const p = await geolocation({ enableHighAccuracy: false, maximumAge: 0, timeout: 15 * 1000 });
        dispatchToAbattoir.gpsLat = p.coords.latitude.toString();
        dispatchToAbattoir.gpsLon = p.coords.longitude.toString();
      } catch (err) {
        console.error("Could not get geolocation: ", err);
      }

      //example sgln: urn:epc:tag:sgln-96:0.6009881028.04.100000000999
      const sgln96Regex = /^urn:epc:tag:sgln-96:0\.[0-9]{10}\.[0-9]{2}\.[0-9]{12}$/gm;
      if (sgln96Regex.test(context.rootState.scan.sgln)) {
        //throw Error("TODO Fix");
        // dispatchToAbattoir.csvLegacy.readerLocation = context.rootState.scan.sgln.slice(-15, -13);
        // dispatchToAbattoir.csvLegacy.readerNumber = context.rootState.scan.sgln.slice(-12);
      }
      dispatchToAbattoir.batchNumber = context.state.batchNumber || "";

      dispatchToAbattoir.notes = context.state.notes;

      dispatchToAbattoir.generateAutoBatchNumber();
      await context.dispatch("data/addDispatchToAbattoir", dispatchToAbattoir, { root: true });
      context.commit("upload/numYetToUpload", "increment", { root: true });
      //throw Error("Some error here blah blah"); //uncomment this line to test Save popup displaying error
      try {
        context.dispatch("upload/upload", undefined, { root: true });
      } catch (e) {
        //silent failure is fine here, upload will likely fail here anyway if the user is still on the scanner's wifi
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
