//tried Int for index and amount but get stupid error (Unknown type \"Int\".)
export const animals = `query animals($guid: String!, $index: Int, $amount: Int, $since: Float, $until: Float) {
  animals(guid: $guid, index: $index, amount: $amount, since: $since, until: $until) {
    guid
    sgtin
    visualTag
    allflex
    events {
      time
      mass
      type
      sourceKraal
      destinationKraal
      brokenNeedle
      station {
        guid
        description
      }
      reference
      system
      temperature
      ailment
      treatment
      vaccination
      doses {
        item
        dosage
        unit
        injected
        withdrawal
      }
      withdrawal
      adg
      days
      massDiff
    }
    breed
    gender
    customFeeder {
      guid
      name
      surname
      companies {
        typename
        guid
        name
        active
      }
    }
    species
  }
}`;
