export default class AppSettings {
  hardwareServerAddress: string = "pi.gigalot.systems";
  hardwareServerRestfulPort: string = "9000";
  hardwareServerReaderPort: string = "8004";
  hardwareServerSecureProtocol: boolean = true;

  onsiteServerAddress: string = "pi.gigalot.systems";
  onsiteServerRestfulPort: string = "7766";
  onsiteServerSecureProtocol: boolean = true;
  //making methods static so that vuex doesn't try to save them
  // static getOnsiteRestfulProtocol(appSettings: AppSettings) {
  //   return appSettings.onsiteServerSecureProtocol ? "https" : "http";
  // }

  static getHardwareRestfulProtocol(appSettings: AppSettings) {
    console.log("hardwareServerSecureProtocol " + appSettings.hardwareServerSecureProtocol);
    return appSettings.hardwareServerSecureProtocol ? "https" : "http";
  }

  static getHardwareWebSocketProtocol(appSettings: AppSettings) {
    return appSettings.hardwareServerSecureProtocol ? "wss" : "ws";
  }

  isDarkMode: boolean = false;
}
