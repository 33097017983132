import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import moment from "moment";

//returns the time (epoch millis) when the maximum withdrawal expires
function withdrawalExpiry(animal: Models.Animal): number | undefined {
  //filter out events with no time or no withdrawal
  const validEvents = animal.events.filter(e => e.time !== undefined && e.withdrawal !== undefined);
  const max = lodash.maxBy(validEvents, e => (e.time as number) + moment.duration(e.withdrawal as number, "days").milliseconds());
  if (max?.time === undefined || max?.withdrawal === undefined) return undefined;
  else return max.time + (moment.duration(max.withdrawal as number, "days").valueOf() as number);
}

export function withdrawalDaysFromToday(animal: Models.Animal): number | undefined {
  const expiry = withdrawalExpiry(animal);
  if (expiry === undefined) return undefined;
  const now = moment(Date.now()).startOf("day");
  const withdrawal = moment(expiry).startOf("day");
  return withdrawal.diff(now, "days");
}
