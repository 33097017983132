import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import Uploadable, { UploadableScanningResult } from "@/models/uploadable";
import { getHhMmSs, getYyyyMmDd } from "@/helpers/date-time";
import { geolocation } from "@/helpers/geolocation";


class CountState {
  batchNumber?: string;
  batchQuantity?: number;
  notes?: string;
  kraalId?: string;
}

export default new (class Count implements Module<CountState, any> {
  namespaced = true;
  state: CountState = new CountState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    batchNumber(state: CountState, batchNumber: string | undefined) {
      state.batchNumber = batchNumber;
    },
    batchQuantity(state: CountState, batchQuantity: number | undefined) {
      state.batchQuantity = batchQuantity;
    },
    notes(state: CountState, notes?: string) {
      state.notes = notes;
    },
    kraalId(state: CountState, kraalId: string | undefined) {
      state.kraalId = kraalId;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async save(context: ActionContext<CountState, any>) {
      let kraalIds: string[] = await context.dispatch("data/getKraalIds", undefined, { root: true });
      if (!context.state.batchNumber) {
        throw Error("No batch reference entered");
      }
      if (context.state.batchQuantity === undefined) {
        throw Error("No quantity entered");
      } else if (context.state.batchQuantity <= 0 || isNaN(context.state.batchQuantity)) {
        throw Error("Invalid batch quantity");
      }
      if (!context.state.kraalId) {
        throw Error("No kraal selected");
      } else if (kraalIds.find(kraalId => kraalId === context.state.kraalId) === undefined) {
        throw Error("Invalid kraal");
      }

      //try to save data in database
      //if error then notify user
      //check available space
      //if success then attempt to send
      //appState === saved should now mean that the data has been saved to the database, but not necessarily received by a server
      //hold onto item's guid
      let metadata: DataModels.UpstreamMetadata = context.rootGetters["upload/getUpstreamMetadata"]();
      let rollCall: UploadableScanningResult = Object.assign(new DataModels.ScanningResult("roll-call", Date.now(), metadata), new Uploadable());
      rollCall.kraalId = context.state.kraalId;
      rollCall.tags = context.rootState.scan.tags;

      const numTags = rollCall.tags.length;
      if (context.state.batchQuantity < numTags) {
        throw Error("Quantity can not be lower than number of tags scanned.");
      }

      rollCall.batchQuantity = "" + context.state.batchQuantity;
      if (!context.state.batchNumber) {
        context.commit("batchNumber", `${getYyyyMmDd(new Date(rollCall.time)).slice(2)}${getHhMmSs(new Date(rollCall.time))}`);
      }

      //try get geolocation
      try {
        const p = await geolocation({ enableHighAccuracy: false, maximumAge: 0, timeout: 15 * 1000 });
        rollCall.gpsLat = p.coords.latitude.toString();
        rollCall.gpsLon = p.coords.longitude.toString();
      } catch (err) {
        console.error("Could not get geolocation: ", err);
      }

      //example sgln: urn:epc:tag:sgln-96:0.6009881028.04.100000000999
      const sgln96Regex = /^urn:epc:tag:sgln-96:0\.[0-9]{10}\.[0-9]{2}\.[0-9]{12}$/gm;
      if (sgln96Regex.test(context.rootState.scan.sgln)) {
        //throw Error("TODO Fix");
        // rollCall.csvLegacy.readerLocation = context.rootState.scan.sgln.slice(-15, -13);
        // rollCall.csvLegacy.readerNumber = context.rootState.scan.sgln.slice(-12);
      }

      rollCall.batchNumber = context.state.batchNumber || "";

      rollCall.notes = context.state.notes;

      rollCall.generateAutoBatchNumber();

      await context.dispatch("data/addRollCall", rollCall, { root: true });
      context.commit("upload/numYetToUpload", "increment", { root: true });
      //throw Error("Some error here blah blah"); //uncomment this line to test Save popup displaying error
      try {
        context.dispatch("upload/upload", undefined, { root: true });
      } catch (e) {
        //silent failure is fine here, upload will likely fail here anyway if the user is still on the scanner's wifi
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
