import * as Models from "@gigalot/data-models";

export function sortTags(tags: Models.Tag[], animals: (Models.Animal & { withdrawalDays?: number; })[]): Models.Tag[] {
  return tags.reverse().sort((t1, t2) => {
    const a1 = animals.find(a => a.sgtin === t1.sgtin);
    const a2 = animals.find(a => a.sgtin === t2.sgtin);
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    if (a1?.withdrawalDays === undefined && a2?.withdrawalDays === undefined) return 0; //equal
    if (a1?.withdrawalDays === undefined && a2?.withdrawalDays !== undefined) return -1;
    if (a1?.withdrawalDays !== undefined && a2?.withdrawalDays === undefined) return 1;
    //if we make it here then a1 and a2 are both not undefined and both have valid withdrawalDays
    return (a2?.withdrawalDays as number) - (a1?.withdrawalDays as number);
  });
}
