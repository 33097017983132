Vue.config.devtools = true;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as RtcClient from "@gigalot/rtc-client";
import mitt from "mitt";
import ReconnectingWebSocket from "reconnecting-websocket";

Vue.config.productionTip = false;

if (window.origin === "https://field.gigalot.co.za" || window.origin.startsWith("https://gigalot-cloud-field-app--preview")) {
  console.log("Production environment detected.");
  store.commit("environment", "production");
} else if (window.origin === "https://field.gigalot.systems") {
  console.log("Staging (cloud) environment detected.");
  store.commit("environment", "staging");
} else {
  console.log("Testing (local) environment detected.");
  store.commit("environment", "testing");
}

let firebaseConfig: any;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAnm2wf7gkEjHhNi59Fv2-U8-isAZhD6UA",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:0a6a4257032f35840e3695",
    measurementId: "G-G59H7Y40V1"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:af322be44c4539a4137021",
    measurementId: "G-M6Y59M5Z6G"
  };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

type RtcEvents = {
  connectionEvent: "connected-cloud" | "connected-local" | "disconnected" | "connecting";
};
export const rtcEmitter = mitt<RtcEvents>();

export function initRtc() {
  try {
    if (!(store.state as any)?.user?.location?.guid) {
      console.error("Can not init WebRTC, no locationGuid found");
      return;
    }
    store.commit("rtcSignalling", "connecting");
    RtcClient.initialize({
      locationGuid: (store.state as any).user.location.guid,
      firebaseConfig: firebaseConfig,
      server: "office",
      debug: false,
      port: 7766,
      onConnectionChange: (connected, signalling, state) => {
        console.log("onConnectionChange");
        console.log("connected", connected);
        console.log("signalling", signalling);
        console.log("state", state);

        if (state === "connecting") {
          store.commit("rtcSignalling", "connecting");
        } else if (state === "connected-local") {
          store.commit("rtcSignalling", "local");
          rtcEmitter.emit("connectionEvent", "connected-local");
        } else if (state === "connected-cloud") {
          store.commit("rtcSignalling", "cloud");
          rtcEmitter.emit("connectionEvent", "connected-cloud");
        } else if (state === "disconnected") {
          store.commit("rtcSignalling", "disconnected");
          rtcEmitter.emit("connectionEvent", "disconnected");
        }
      },
      getJwt: () => store.dispatch("user/getOnlineIdToken", undefined, { root: true }),
      project: store.state.environment === "production" ? "gigalot-cloud" : "gigalot-testing",
      reconnect: {
        retryTime: 5000,
        maxRetryTime: 30 * 1000
      }
    });
  } catch (err) {
    console.error(err);
  }
}

store.commit("rtcSignalling", "disconnected");
store.dispatch("user/addFirebaseCallback", async () => {
  initRtc();
});

firebase.auth().onAuthStateChanged(function (user) {
  store.dispatch("user/firebaseOnAuthStateChanged", user);
  store.dispatch("certificates/getCertificateCloud");
  connectProxySocket();
});

store.commit("version");

let webSocketConnectedToProxy: ReconnectingWebSocket;

function connectProxySocket() {

  webSocketConnectedToProxy = new ReconnectingWebSocket(`wss://${store.getters["settings/hslAddress"]()}:9000/webSocketConnectedToProxy`);

  webSocketConnectedToProxy.addEventListener("open", event => {
    console.log("webSocketConnectedToProxy open");
    if (store.state.connectedToProxy !== true) store.commit("connectedToProxy", true);
    startMessageInterval();
    store.dispatch("certificates/onConnectedToProxy");
  });
  webSocketConnectedToProxy.addEventListener("close", event => {
    console.log("webSocketConnectedToProxy close");
    if (store.state.connectedToProxy !== false) store.commit("connectedToProxy", false);
    stopMessageInterval();
  });
}

////////

let t: NodeJS.Timeout | undefined = undefined;

function startMessageInterval() {
  if (t) return;
  t = setInterval(() => {
    try {
      webSocketConnectedToProxy?.send("x");
    } catch (err) {
      console.error("failed to send heartbeat message:", err);
    }
  }, 10 * 1000);
}

function stopMessageInterval() {
  if (t) clearInterval(t);
  t = undefined;
}

////////


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
