import { default as Vuex, Module, ActionContext } from "vuex";
import { DataManager, ObjectStoreNames } from "@/data-manager";
import { UploadableScanningResult, UploadableModel } from "@/models/uploadable";
import * as Models from "@gigalot/data-models";
import { HospitalResult } from "@gigalot/data-models";
import { sgtinUiFriendlyTag } from "@/helpers/sgtin-ui-friendly-tag";
import { animalQueue } from "@/helpers/downloaded-animal-queue";

const dataManager: DataManager = new DataManager();

class DataState {
  dataManager: DataManager = dataManager;
}

//let temporaryAnimalsStore: Models.Animal[] = []; //clear this when user changes locations

class DataModule implements Module<DataState, any> {
  namespaced = true;
  state: DataState = new DataState();
  mutations = {
  };
  actions = {
    async onAppCreated(context: ActionContext<DataState, any>) {
    },
    async clearDownloadedData(context: ActionContext<DataState, any>) {
      animalQueue.clear();
      return dataManager.clearDownloadedData();
    },
    async addItemsToDb(
      context: ActionContext<DataState, any>,
      obj: {
        objectStoreName: ObjectStoreNames;
        items: any[];
        onFinishedSaving?: () => Promise<void>;
      }
    ) {
      if (obj.objectStoreName === "animals") {
        if (obj.onFinishedSaving) animalQueue.setQueueFinishedCallback(obj.onFinishedSaving)
        if (!animalQueue.hasCallback()) {
          animalQueue.setItemCallback(async (animal: Models.Animal) => {
            await dataManager.addItems("animals", [animal])
          });
        }
        animalQueue.enqueue(obj.items)

      } else {
        await dataManager.addItems(obj.objectStoreName, obj.items, /*obj.progressCb*/);
      }
    },
    async fetchPost(context: ActionContext<DataState, any>, o: { url: string; body: any; }) {
      console.log("body: " + o.body);
      let res = await fetch(o.url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: o.body
      });
      if (!res.ok) throw Error(`fetchPost (url: ${o.url}) reponse not OK : ${await res.text()}`);
      return await res.json();
    },
    async getAnimal(context: ActionContext<DataState, any>, sgtin: string) {
      const a = animalQueue.queue.find(a => a.sgtin === sgtin);
      if (a) return a;
      else return await dataManager.getAnimal(sgtin);
    },
    async getAnimalList(context: ActionContext<DataState, any>) {

      return await dataManager.mapOverAnimals((a: Models.Animal) => ({
        typename: a.typename,
        guid: a.guid,
        sgtinUiFriendlyTag: sgtinUiFriendlyTag(a.sgtin),
        owner: a.owner ?? a.customFeeder?.name,
        breed: a.breed,
        gender: a.gender,
        sgtin: a.sgtin,
        allflex: a.allflex,
        visualTag: a.visualTag
      }));
    },
    getKraalIds(context: ActionContext<DataState, any>) {
      return dataManager.getKraalIds();
    },
    getAilments(context: ActionContext<DataState, any>) {
      return dataManager.getItems("ailments");
    },
    getAilment(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getItem("ailments", guid);
    },
    getTreatments(context: ActionContext<DataState, any>) {
      return dataManager.getItems("treatments");
    },
    getTreatment(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getItem("treatments", guid);
    },
    async getObjectStoreCount(context: ActionContext<DataState, any>, objectStoreName: ObjectStoreNames) {
      return dataManager.getObjectStoreCount(objectStoreName);
    },
    addRollCall(context: ActionContext<DataState, any>, item: UploadableScanningResult) {
      return dataManager.addItems("saved-roll-calls", [item]);
    },
    getRollCall(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedRollCall(guid);
    },
    getRollCalls(context: ActionContext<DataState, any>) {
      return dataManager.getItems("saved-roll-calls");
    },
    addHospitalResult(context: ActionContext<DataState, any>, item: HospitalResult) {
      return dataManager.addItems("saved-hospital-results", [item]);
    },
    getHospitalResult(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedHospitalResult(guid);
    },
    getHospitalResults(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedHospitalResults();
    },
    addDispatchToAbattoir(context: ActionContext<DataState, any>, item: UploadableScanningResult) {
      return dataManager.addItems("saved-abattoir-dispatches", [item]);
    },
    getDispatchToAbattoir(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedDispatchToAbattoir(guid);
    },
    getDispatchesToAbattoirs(context: ActionContext<DataState, any>) {
      return dataManager.getItems("saved-abattoir-dispatches");
    },
    addGroupWeigh(context: ActionContext<DataState, any>, item: UploadableScanningResult) {
      return dataManager.addItems("saved-group-weighs", [item]);
    },
    getGroupWeigh(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedGroupWeigh(guid);
    },
    getGroupWeighs(context: ActionContext<DataState, any>) {
      return dataManager.getItems("saved-group-weighs");
    },
    addHeadCount(context: ActionContext<DataState, any>, item: UploadableScanningResult) {
      return dataManager.addItems("saved-head-counts", [item]);
    },
    getHeadCount(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedHeadCount(guid);
    },
    getHeadCounts(context: ActionContext<DataState, any>) {
      return dataManager.getItems("saved-head-counts");
    },
    async deleteData(context: ActionContext<DataState, any>, objectStoreName: "animals" | "kraals" | "ailments" | "treatments") {
      return dataManager.deleteData(objectStoreName);
    },
    async deleteAnimal(context: ActionContext<DataState, any>, sgtin: string) {
      return dataManager.deleteItems([{ guid: sgtin }], "animals");
    },
    async getNumAnimalsInQueue(context: ActionContext<DataState, any>) {
      return animalQueue.queue.length;
    }
  };
  getters = {
  };
}

const dataModule: DataModule = new DataModule();

export default dataModule;
