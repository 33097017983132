import { default as Vuex, Module } from "vuex";

type PopupDialogMode = "ok" | "yesNo";

class PopupDialogState {
  [key: string]: any;
  visible: boolean = false;
  popupMessage: string = "";
  mode: PopupDialogMode = "ok";
  yesAction: Function = () => { };
}

class PopupDialogModule implements Module<PopupDialogState, any> {
  namespaced = true;
  state: PopupDialogState = new PopupDialogState();
  mutations = {
    hide(state: PopupDialogState) {
      state.visible = false;
      state.popupMessage = "";
      /*
      Deciding to keep yesAction after hiding because I found a case where I need to hide a yes no popup first and then run the yesAction.
      The yesAction may result in an OK popup being displayed, but the OK popup is not displayed if the yes no popup is still being displayed.
      The popup module could probably do with some queue system but this is a much easier fix for now.

      So no the v-dialog in App.vue first hides the popup and then runs the yesAction (it used to be the other way round).
      */
      //state.yesAction = () => {};
    },
    displayOk(state: PopupDialogState, message: string) {
      state.visible = true;
      state.mode = "ok";
      state.popupMessage = message;
    },
    displayYesNo(state: PopupDialogState, payload: { message: string; yesAction: Function; }) {
      state.visible = true;
      state.mode = "yesNo";
      state.popupMessage = payload.message;
      state.yesAction = payload.yesAction;
    }
  };
}

const popupDialogModule: PopupDialogModule = new PopupDialogModule();

export default popupDialogModule;
