import { default as Vuex, Module, ActionContext } from "vuex";
import lodash from "lodash";

export type MassCaptureState = "not-capturing" | "capturing" | "captured" | "failed";

class ScaleState {
  connected: boolean = false;
  massQueueSize: number = 50;
  massQueue: number[] = [];
  currentMass?: number = undefined;
  massCaptureState: MassCaptureState = "not-capturing";
  capturedMass?: number;

  sampleSize: number = 20;
  epsilon: number = 20 * 2.5; //50.0

  minMass: number = 100; //kg
  maxMass: number = 10000; //kg

  timeout: number = 10; //how many seconds before mass capture times out.
}

let webSocket: WebSocket | undefined = undefined;

// let cancelTimer = (context: any) => {
//   let timeout = this.setTimeout;
//   lodash.debounce(() => {

//   }, )
// }

let cancelTimer: any = undefined;

function startTimeoutTimer(context: ActionContext<ScaleState, any>) {
  cancelTimer = lodash.debounce(() => {
    console.log("mass capture timeout");
    context.commit("setMassCaptureState", "failed");
  }, context.state.timeout * 1000);
  cancelTimer();
}

function cancelTimeoutTimer() {
  if (cancelTimer) cancelTimer.cancel();
}

//TODO: attempt to reconnect scale after it has disconnected

export default new (class Scale implements Module<ScaleState, any> {
  namespaced = true;
  state: ScaleState = new ScaleState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    reset(state: ScaleState) {
      state.connected = false;
      state.currentMass = undefined;
      state.massQueue = [];
      state.massCaptureState = "not-capturing";
    },
    addRunningMass(state: ScaleState, mass: number) {
      state.currentMass = mass;
      state.massQueue.push(mass);
      if (state.massQueue.length > state.massQueueSize) {
        state.massQueue.shift();
      }

      if (state.massCaptureState === "capturing" && state.massQueue.length >= state.sampleSize) {
        let samples: number[] = state.massQueue.slice(state.sampleSize * -1);

        let avg = lodash.mean(samples);

        let total = 0;
        samples.forEach((sample: number) => {
          total += Math.abs(sample - avg);
        });

        if (total < state.epsilon && mass >= state.minMass && mass <= state.maxMass) {
          cancelTimeoutTimer();
          state.capturedMass = avg;
          state.massCaptureState = "captured";
        }
      }
    },
    clearMass(state: ScaleState) {
      state.capturedMass = undefined;
    },
    setConnected(state: ScaleState, connected: boolean) {
      state.connected = connected;
    },
    setMassCaptureState(state: ScaleState, massCaptureState: MassCaptureState) {
      state.massCaptureState = massCaptureState;
    }
    // setCapturedMass(state: State, capturedMass: number) {
    //   state.capturedMass = capturedMass;
    //   state.massCaptureState = "captured";
    // }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    connect(context: ActionContext<ScaleState, any>) {
      webSocket = new WebSocket("ws://127.0.0.1:8006");
      webSocket.onopen = event => {
        context.commit("setConnected", true);
      };
      webSocket.onerror = event => {
        context.commit("setConnected", false);
        context.commit("setMassCaptureState", "failed");
        cancelTimeoutTimer();

        console.log("webSocket error: ", event);
      };
      webSocket.onclose = event => {
        cancelTimeoutTimer();
        context.commit("setConnected", false);
      };
      webSocket.onmessage = message => {
        if (!context.state.connected) context.commit("setConnected", true);
        //console.log(message.data);
        context.commit("addRunningMass", +message.data);
      };
    },
    disconnect(context: ActionContext<ScaleState, any>) {
      cancelTimeoutTimer();
      if (webSocket) webSocket.close();
      context.commit("setConnected", false);
    },
    captureMass(context: ActionContext<ScaleState, any>) {
      if (context.state.massCaptureState === "capturing") return;

      if (!context.state.connected) {
        console.log("not connected to web socket");
        return;
      }

      context.commit("setMassCaptureState", "capturing");
      startTimeoutTimer(context);

      //start the cancel timer
      //if cancel timer reached before capturing mass then mass capture fails
      //if mass capture is successful then cancel the cancel timer
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
