import { openDB, DBSchema, IDBPDatabase, StoreNames } from "idb/with-async-ittr";
import { UploadableScanningResult, UploadableModel, HospitalResult } from "./models/uploadable";

import { Kraal, /*MorbidityDescription,*/ Treatment, Vaccination, Dose, Ailment, Animal } from "@gigalot/data-models";

import store from "@/store/store";

interface MyDB extends DBSchema {
  animals: {
    key: string;
    indexes: {
      locationGuid: string;
      "by-kraal": string; //not used
      "by-visual-ear-tag": string; //not used
    };
    value: any;
  };
  kraals: {
    key: string;
    value: Kraal;
    indexes: { locationGuid: string; };
  };
  ailments: { key: string; value: Ailment; indexes: { locationGuid: string; }; };
  treatments: { key: string; value: Treatment; indexes: { locationGuid: string; }; };
  "saved-roll-calls": {
    key: string;
    value: UploadableScanningResult;
    indexes: { locationGuid: string; };
  };
  "saved-kraal-dispatches": {
    key: string;
    value: UploadableScanningResult;
    indexes: { locationGuid: string; };
  };
  "saved-abattoir-dispatches": {
    key: string;
    value: UploadableScanningResult;
    indexes: { locationGuid: string; };
  };
  "saved-hospital-results": {
    key: string;
    value: HospitalResult;
    indexes: { locationGuid: string; };
  };
  "saved-group-weighs": {
    key: string;
    value: UploadableScanningResult;
    indexes: { locationGuid: string; };
  };
  "saved-head-counts": {
    key: string;
    value: UploadableScanningResult;
    indexes: { locationGuid: string; };
  };
  //not being used, can leave here for now
  "saved-crib-scores": {
    key: string;
    value: UploadableModel;
    indexes: { locationGuid: string; };
  };
  //not being used, can leave here for now
  "todays-crib-scores": {
    key: string;
    value: any;
    indexes: { locationGuid: string; };
  };
  //not being used, can leave here for now
  "crib-scores-history": {
    key: string;
    value: any;
    indexes: { locationGuid: string; };
  };
  //Keep these for legacy, not being used.
  "legacy-animals": {
    key: string;
    value: any;
    indexes: { locationGuid: string; };
  };
  //Keep these for legacy, not being used.
  "legacy-data": {
    key: string;
    value: any;
    indexes: { locationGuid: string; };
  };
  //Keep these for legacy, not being used.
  "treatment-descriptions": {
    key: string;
    value: Treatment;
    indexes: { locationGuid: string; };
  };
  //Keep these for legacy, not being used.
  "vaccination-descriptions": {
    key: string;
    value: Vaccination;
    indexes: { locationGuid: string; };
  };
  //Keep these for legacy, not being used.
  "dispensary-items": {
    key: string;
    value: Dose;
    indexes: { locationGuid: string; };
  };
}

export type ObjectStoreNames = StoreNames<MyDB>;

const DB_VERSION = 14;

export class DataManager {
  _db?: Promise<IDBPDatabase<MyDB>> = undefined;

  private async getDb() {
    if (!this._db) {
      throw new Error("_db unavailable");
    }
    return await this._db;
  }

  constructor() {
    this.initializeDatabase();
  }

  async onDatabaseInitialized() {
    const self = this;
    const deleteOldAndUploaded = async function (objectStoreName: ObjectStoreNames) {
      const savedItems: any[] = (await self.getItems(objectStoreName)) as any[];
      const oldAndUploaded = savedItems.filter((item: any) => {
        const time = item.time ?? item.readingDate;
        const age = (Date.now() - time) / 1000 / 60 / 60 / 24; //in days
        return age >= 30 && item.uploaded;
      });

      await self.deleteItems(oldAndUploaded, objectStoreName);
    };

    await deleteOldAndUploaded("saved-abattoir-dispatches");
    await deleteOldAndUploaded("saved-crib-scores"); //remove this eventually
    await deleteOldAndUploaded("saved-group-weighs");
    await deleteOldAndUploaded("saved-kraal-dispatches");
    await deleteOldAndUploaded("saved-roll-calls");
    await deleteOldAndUploaded("saved-hospital-results");
  }

  initializeDatabase() {
    //console.log("data-manager initializeDatabase");
    this._db = openDB<MyDB>("field-app-DB", DB_VERSION, {
      upgrade(db, oldVersion, newVersion, transaction) {
        //console.log("initializeDatabase()");
        console.log(`oldVersion: ${oldVersion}`);
        console.log(`newVersion: ${newVersion}`);

        if (oldVersion < 1) {
          //First version
          const animalsStore = db.createObjectStore("animals", { keyPath: "sgtin" });
          animalsStore.createIndex("by-kraal", "kraalId");
          //animalsStore.createIndex("by-visual-ear-tag", "vistag");
          db.createObjectStore("legacy-data", { keyPath: "typename" }); //only one copy allowed
          db.createObjectStore("legacy-animals", { keyPath: "sgtin" });
          //TODO
          //db.createObjectStore("morbidity-descriptions", { keyPath: "id" });
          db.createObjectStore("kraals", { keyPath: "kraalId" });

          db.createObjectStore("saved-roll-calls", { keyPath: "guid" });
          //db.createObjectStore("saved-dispatches", { keyPath: "guid" });
        }

        if (oldVersion < 2) {
          //Second version, introducing saved-kraal-dispatches object store
          db.createObjectStore("saved-kraal-dispatches", { keyPath: "guid" });
        }

        if (oldVersion < 3) {
          //Third version, add visual tag as index to animals.

          let animalsObjectStore = transaction.objectStore("animals");
          animalsObjectStore.createIndex("by-visual-ear-tag", "visualTagNumber");
        }

        if (oldVersion < 4) {
          //Fourth version, adding treatment and vaccination descriptions
          db.createObjectStore("treatment-descriptions", { keyPath: "treatmentId" });
          db.createObjectStore("vaccination-descriptions", { keyPath: "vaccinationId" });
        }

        if (oldVersion < 5) {
          //Fifth version, adding saved abattoir dispatches
          db.createObjectStore("saved-abattoir-dispatches", { keyPath: "guid" });
        }

        if (oldVersion < 6) {
          //Sixth version, adding saved group weighs
          db.createObjectStore("saved-group-weighs", { keyPath: "guid" });
        }

        if (oldVersion < 7) {
          db.createObjectStore("dispensary-items", { keyPath: "id", autoIncrement: true });
        }
        if (oldVersion < 8) {
          db.createObjectStore("saved-crib-scores", { keyPath: "guid" });
        }
        if (oldVersion < 9) {
          db.createObjectStore("todays-crib-scores", { keyPath: "kraalId" });
          db.createObjectStore("crib-scores-history", { keyPath: "guid" });
        }
        if (oldVersion < 10) {
          const toDelete: ObjectStoreNames[] = ["legacy-animals", "legacy-data", "treatment-descriptions", "vaccination-descriptions", "dispensary-items"];

          for (const itemToDelete of toDelete) {
            if (db.objectStoreNames.contains(itemToDelete)) {
              console.log(`Deleting ${itemToDelete}`);
              db.deleteObjectStore(itemToDelete);
            }
          }

          if (db.objectStoreNames.contains("animals")) db.deleteObjectStore("animals"); //remove indexes
          db.createObjectStore("animals", { keyPath: "sgtin" });

          transaction.objectStore("animals").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("kraals").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("saved-roll-calls").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("saved-kraal-dispatches").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("saved-abattoir-dispatches").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("saved-group-weighs").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("saved-crib-scores").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("todays-crib-scores").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("crib-scores-history").createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 12) {
          const hospitalResultStore = db.createObjectStore("saved-hospital-results", { keyPath: "guid" });
          hospitalResultStore.createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 13) {
          db.createObjectStore("ailments", { keyPath: "guid" });
          db.createObjectStore("treatments", { keyPath: "guid" });
          transaction.objectStore("ailments").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("treatments").createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 14) {
          db.createObjectStore("saved-head-counts", { keyPath: "guid" });
          transaction.objectStore("saved-head-counts").createIndex("locationGuid", "locationGuid");
        }

        //TODO: remove crib score object stores in a new idb version
        //TODO: remove saved-kraal-dispatches from idb as well
        //TODO: add ailments and treatments for hospital function, don't forget to index by locationGuid
      }
    });
    return this._db.then(db => {
      this.onDatabaseInitialized();
    });
  }

  async addItems(objectStoreName: ObjectStoreNames, items: any[]/*, progressCb?: (loaded: number, total: number) => void*/) {
    //TODO: add locationGuid to items before adding them to idb

    //skipping try/catch here so that outer calling methods can catch exceptions thrown
    const locationGuid = (store.state as any).user.location?.guid;
    if (!locationGuid) throw Error("dataManager.addItems error: No location guid found!");
    for (let item of items) item.locationGuid = locationGuid;
    const db = await this.getDb();
    const tx = db.transaction(objectStoreName, "readwrite");
    const os = tx.store;
    for (let i = 0; i < items.length; ++i) {
      //await os.add(items[i]);
      await os.put(items[i]);
      //progressCb?.(i + 1, items.length);
    }
    return tx.done;
  }

  clearDownloadedData() {
    //console.log("clear database");
    if (!this._db) {
      throw new Error("_db unavailable");
    }
    return this._db.then(async function (db) {
      const objectstores: ObjectStoreNames[] = ["animals", "kraals", "ailments", "treatments"]
      //return Promise.all([db.clear("animals"), db.clear("kraals")]);
      return Promise.all(objectstores.map(o => db.clear(o)))
    });
  }

  async deleteData(objectStoreName: ObjectStoreNames) {
    const db = await this.getDb();
    await db.clear(objectStoreName);
  }

  async clearAnimals() {
    const db = await this.getDb();
    await db.clear("animals");
  }

  async getObjectStoreCount(objectStoreName: ObjectStoreNames): Promise<number> {
    const db = await this.getDb();
    //const locationGuid: any = (store.state as any).user.location?.guid;
    //if (!locationGuid) throw Error("dataManager.getItems error: No location guid found!");
    //TODO: maybe just use db.count
    //return await db.countFromIndex(objectStoreName, "locationGuid", locationGuid);
    return await db.count(objectStoreName);
  }

  async getAnimal(sgtin: string) {
    let ret = await this.getItem("animals", sgtin);
    if (ret && !ret.owner) {
      ret.owner = ret.customFeeder?.name
    }
    return ret;
  }

  async getAnimalsList() {
    return await this.getItems("animals");
  }

  async mapOverAnimals(f: (animal: Animal) => any) {
    const db = await this.getDb();
    let cursor = await db
      .transaction("animals", "readonly")
      .store.openCursor();
    const ret = [];
    while (cursor) {
      if (!cursor.value.finished) ret.push(f(cursor.value));
      cursor = await cursor.continue();
    }
    return ret;
  }

  async getItems(objectStoreName: ObjectStoreNames) {
    // const locationGuid: any = (store.state as any).user.location?.guid;
    // if (!locationGuid) throw Error("dataManager.getItems error: No location guid found!");
    // const db = await this.getDb();
    // const items = await db
    //   .transaction(objectStoreName)
    //   .store.index("locationGuid") //TODO: only get items that match user's selected locationGuid
    //   .getAll(locationGuid);

    //const locationGuid: any = (store.state as any).user.location?.guid;
    //if (!locationGuid) throw Error("dataManager.getItems error: No location guid found!");
    const db = await this.getDb();
    const items = await db
      .transaction(objectStoreName)
      .store.getAll();

    //TODO: delete locationGuid (not from db) from items before returning them
    for (let item of items) delete item.locationGuid;
    return items;
  }

  async getItem(objectStoreName: ObjectStoreNames, key: string) {
    // const locationGuid: any = (store.state as any).user.location?.guid;
    // if (!locationGuid) throw Error("dataManager.getItem error: No location guid found!");
    // const db = await this.getDb();
    // let item = await db.transaction(objectStoreName).store.get(key);
    // if (item?.locationGuid !== locationGuid) {
    //   console.error("datamanager.getItem blocked because locationGuid mismatch detected!");
    //   return undefined;
    // }

    //const locationGuid: any = (store.state as any).user.location?.guid;
    //if (!locationGuid) throw Error("dataManager.getItem error: No location guid found!");
    const db = await this.getDb();
    let item = await db.transaction(objectStoreName).store.get(key);
    // if (item?.locationGuid !== locationGuid) {
    //   console.error("datamanager.getItem blocked because locationGuid mismatch detected!");
    //   return undefined;
    // }

    //TODO: delete locationGuid (not from db) from items before returning them
    if (item) delete item.locationGuid;
    return item;
  }

  async getSavedRollCall(guid: string) {
    return await this.getItem("saved-roll-calls", guid);
  }
  async getSavedHeadCount(guid: string) {
    return await this.getItem("saved-head-counts", guid);
  }
  // async getSavedDispatchToKraal(guid: string) {
  //   return await this.getItem("saved-kraal-dispatches", guid);
  // }

  async getSavedDispatchToAbattoir(guid: string) {
    return await this.getItem("saved-abattoir-dispatches", guid);
  }

  async getSavedGroupWeigh(guid: string) {
    return await this.getItem("saved-group-weighs", guid);
  }
  async getSavedHospitalResults() {
    return await this.getItems("saved-hospital-results");
  }
  async getSavedHospitalResult(guid: string) {
    return await this.getItem("saved-hospital-results", guid);
  }

  async deleteItems(items: any[], objectStoreName: ObjectStoreNames) {
    const db = await this.getDb();
    const os = db.transaction(objectStoreName, "readwrite").store;
    for (let i = 0; i < items.length; ++i) {
      await os.delete(items[i].guid);
    }
  }

  async getKraalIds() {
    // const locationGuid: any = (store.state as any).user.location?.guid;
    // if (!locationGuid) throw Error("dataManager.getKraalIds error: No location guid found!");
    // const db = await this.getDb();
    // return db
    //   .transaction("kraals", "readonly")
    //   .store.index("locationGuid")
    //   .getAllKeys(locationGuid);

    //const locationGuid: any = (store.state as any).user.location?.guid;
    //if (!locationGuid) throw Error("dataManager.getKraalIds error: No location guid found!");
    const db = await this.getDb();
    return db
      .transaction("kraals", "readonly")
      .store.getAllKeys();
  }

  async getSgtins() {
    // const locationGuid: any = (store.state as any).user.location?.guid;
    // if (!locationGuid) throw Error("dataManager.getSgtins error: No location guid found!");
    // const db = await this.getDb();
    // return db
    //   .transaction("animals", "readonly")
    //   .store.index("locationGuid")
    //   .getAllKeys(locationGuid);

    //const locationGuid: any = (store.state as any).user.location?.guid;
    //if (!locationGuid) throw Error("dataManager.getSgtins error: No location guid found!");
    const db = await this.getDb();
    return db
      .transaction("animals", "readonly")
      .store.getAllKeys();
  }

  //Look for any items in any objectstore that have no locationGuid set, and update them with user's current locationGuid
  // async updateAllHomelessItems(locationGuid: string) {
  //   const db = await this.getDb();
  //   for (const objectStoreName of db.objectStoreNames) {
  //     //console.log(objectStoreName);
  //     const tx = db.transaction(objectStoreName, "readwrite");
  //     for await (const cursor of tx.store) {
  //       //console.log(cursor.value);
  //       let o = cursor.value;
  //       if (!o.locationGuid) {
  //         o.locationGuid = locationGuid;
  //         await tx.store.put(o);
  //       }
  //       cursor.continue();
  //     }
  //   }
  // }
}
