import Vue from "vue";
import Router, { Route, NavigationGuard } from "vue-router";
import Home from "../views/Home.vue";
import Count from "../views/scanning-functions/Count.vue";
import HeadCount from "../views/scanning-functions/HeadCount.vue";
import AutoCount from "../views/scanning-functions/AutoCount.vue";
import DispatchToKraal from "../views/scanning-functions/DispatchToKraal.vue";
import DispatchToAbattoir from "../views/scanning-functions/DispatchToAbattoir.vue";
import GroupWeigh from "../views/scanning-functions/group-weigh/GroupWeigh.vue";
import AddMass from "../views/scanning-functions/group-weigh/AddMass.vue";
import ViewData from "../views/view-data/ViewData.vue";
import AnimalSearch from "../views/view-data/AnimalSearch.vue";
import AnimalData from "../views/view-data/AnimalData.vue";
import Sync from "../views/Sync.vue";
import History from "../views/History.vue";
import Login from "../views/Login.vue";
import Location from "@/views/Location.vue";
import Settings from "../views/Settings.vue";
import System from "../views/System.vue";
import Certificates from "../views/Certificates.vue";
import Hospital from "../views/hospital/Hospital.vue";
//import HospitalTreatment from "../views/hospital/HospitalTreatment.vue";
import Selection from "../views/Selection.vue";
import store from "../store/store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      alias: ["/index.html", "/index"],
      name: "home",
      component: Home,
      meta: { requiresRegistration: true }
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "./views/About.vue")
    // }
    {
      path: "/auto-count",
      name: "auto-count",
      component: AutoCount,
      props: { saveEnabled: true },
      meta: { requiresRegistration: true },
    },
    {
      path: "/count",
      name: "count",
      component: Count,
      props: { saveEnabled: true },
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (store.getters["scan/isAppModeAllowedToContinue"]("count")) next();
        else next("/");
      }
    },
    {
      path: "/count-without-save",
      name: "count-without-save",
      component: Count,
      props: { saveEnabled: false },
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (store.getters["scan/isAppModeAllowedToContinue"]("count-without-save")) next();
        else next("/");
      }
    },
    {
      path: "/head-count",
      name: "head-count",
      component: HeadCount,
      props: { saveEnabled: true },
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (store.getters["scan/isAppModeAllowedToContinue"]("head-count")) next();
        else next("/");
      }
    },
    {
      path: "/dispatch-to-abattoir",
      name: "dispatch-to-abattoir",
      component: DispatchToAbattoir,
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (store.getters["scan/isAppModeAllowedToContinue"]("dispatch-to-abattoir")) next();
        else next("/");
      }
    },
    {
      path: "/group-weigh",
      name: "group-weigh",
      component: GroupWeigh,
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (store.getters["scan/isAppModeAllowedToContinue"]("group-weigh")) next();
        else next("/");
      }
    },
    {
      path: "/group-weigh/add-mass",
      name: "group-weigh/add-mass",
      component: AddMass,
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (from.name !== "group-weigh") next("/group-weigh");
        else next();
      }
    },
    {
      path: "/sync",
      name: "sync",
      component: Sync,
      meta: { requiresRegistration: true }
    },
    {
      path: "/view-data",
      name: "view-data",
      component: ViewData,
      meta: { requiresRegistration: true }
    },
    {
      path: "/view-data/animal-search",
      name: "animal-search",
      component: AnimalSearch,
      props: { nextRouteName: "animal-data" },
      meta: { requiresRegistration: true }
    },
    {
      path: "/view-data/animal-data",
      name: "animal-data",
      component: AnimalData,
      meta: { requiresRegistration: true },
      props: true
    },
    {
      path: "/hospital/animal-search",
      name: "hospital-animal-search",
      component: AnimalSearch,
      props: { nextRouteName: "hospital" },
      meta: { requiresRegistration: true }
    },
    {
      path: "/hospital",
      name: "hospital",
      component: Hospital,
      meta: { requiresRegistration: true }
    },
    {
      path: "/outbox",
      name: "outbox",
      component: History,
      //props: { hideUploaded: true },
      meta: { requiresRegistration: true }
    },
    // {
    //   path: "/history",
    //   name: "history",
    //   component: History,
    //   props: { hideUploaded: false },
    //   meta: { requiresRegistration: true }
    // },
    {
      path: "/selection",
      name: "selection",
      component: Selection,
      props: { hideUploaded: false },
      meta: { requiresRegistration: true },
      beforeEnter: (to: Route, from: Route, next: any) => {
        let storeState: any = store.state;
        if (storeState.selection.itemTypeName) next();
        else next("/");
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: { requiresRegistration: true }
    },
    {
      path: "/system",
      name: "system",
      component: System,
      meta: { requiresRegistration: true }
    },
    {
      path: "/certificates",
      name: "certificates",
      component: Certificates,
      meta: { requiresRegistration: true }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/location",
      name: "location",
      component: Location
    }
  ]
});

router.beforeEach((to: Route, from: Route, next: any) => {
  let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
  let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

  if (requiresRegistration && !isUserRegistered) {
    next("login");
  } else if (to.name !== "location" && to.name !== "login" && !(store as any).state.user.location) {
    next("location");
  } else {
    next();
  }
});

export default router;
