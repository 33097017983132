

















import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { AppMode } from "@/store/modules/scan";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Selection extends Vue {}
