import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import Uploadable, { UploadableScanningResult } from "@/models/uploadable";
import { getHhMmSs, getYyyyMmDd } from "@/helpers/date-time";
import { geolocation } from "@/helpers/geolocation";

class GroupWeighState {
  kraalId?: string;
  batchNumber?: string;
  notes?: string;
  massesQuantities: { type: "manual" | "auto"; mass: number; quantity?: number; time: number; }[] = [];
}

export default new (class GroupWeigh implements Module<GroupWeighState, any> {
  namespaced = true;
  state: GroupWeighState = new GroupWeighState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    kraalId(state: GroupWeighState, kraalId: string | undefined) {
      state.kraalId = kraalId;
    },
    batchNumber(state: GroupWeighState, batchNumber: string | undefined) {
      state.batchNumber = batchNumber;
    },
    notes(state: GroupWeighState, notes?: string) {
      state.notes = notes;
    },
    addMassManual(state: GroupWeighState, payload: { mass: number; quantity: number; }) {
      state.massesQuantities.push({ type: "manual", mass: payload.mass, quantity: payload.quantity, time: Date.now() });
    },
    removeMass(state: GroupWeighState, index: number) {
      state.massesQuantities.splice(index, 1); //removes element from array
    },
    clearMasses(state: GroupWeighState) {
      state.massesQuantities = [];
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async save(context: ActionContext<GroupWeighState, any>) {
      if (!context.state.batchNumber) {
        throw Error("No batch reference entered");
      }
      if (context.state.massesQuantities.length === 0) {
        throw Error("Masses and quantities must be entered");
      }
      let kraalIds: string[] = await context.dispatch("data/getKraalIds", undefined, { root: true });
      if (!context.state.kraalId) {
        throw Error("No kraal selected");
      } else if (kraalIds.find(kraalId => kraalId === context.state.kraalId) === undefined) {
        throw Error("Invalid kraal");
      }
      let metadata: DataModels.UpstreamMetadata = context.rootGetters["upload/getUpstreamMetadata"]();
      let groupWeigh: UploadableScanningResult = Object.assign(new DataModels.ScanningResult("group-weigh", Date.now(), metadata), new Uploadable());
      groupWeigh.kraalId = context.state.kraalId;
      groupWeigh.tags = context.rootState.scan.tags;
      //groupWeigh.batchQuantity = "" + context.state.batchQuantity; //can get this from adding up quantities in mass
      if (!context.state.batchNumber) {
        context.commit("batchNumber", `${getYyyyMmDd(new Date(groupWeigh.time)).slice(2)}${getHhMmSs(new Date(groupWeigh.time))}`);
      }

      //try get geolocation
      try {
        const p = await geolocation({ enableHighAccuracy: false, maximumAge: 0, timeout: 15 * 1000 });
        groupWeigh.gpsLat = p.coords.latitude.toString();
        groupWeigh.gpsLon = p.coords.longitude.toString();
      } catch (err) {
        console.error("Could not get geolocation: ", err);
      }

      //example sgln: urn:epc:tag:sgln-96:0.6009881028.04.100000000999
      const sgln96Regex = /^urn:epc:tag:sgln-96:0\.[0-9]{10}\.[0-9]{2}\.[0-9]{12}$/gm;
      if (sgln96Regex.test(context.rootState.scan.sgln)) {
        //throw Error("TODO Fix");
      }
      groupWeigh.batchNumber = context.state.batchNumber || "";

      groupWeigh.notes = context.state.notes;

      groupWeigh.mass = context.state.massesQuantities;
      groupWeigh.generateAutoBatchNumber();
      await context.dispatch("data/addGroupWeigh", groupWeigh, { root: true });
      context.commit("upload/numYetToUpload", "increment", { root: true });
      //throw Error("Some error here blah blah"); //uncomment this line to test Save popup displaying error
      try {
        context.dispatch("upload/upload", undefined, { root: true });
      } catch (e) {
        //silent failure is fine here, upload will likely fail here anyway if the user is still on the scanner's wifi
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
